import { BASEURL } from "@CON";

async function downloadExcelSample(url) {
  // const res = await axios.get(url);
  // const fileUrl = window.URL.createObjectURL(new Blob([res]));

  const response = await fetch(url);
  const blob = await response.blob();

  let filename = url.split("/")[2];
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
}

async function downloadLCA(urlList) {
  urlList.forEach(async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    let filename = url.split("_")[2];
    link.setAttribute("download", filename);
    link.setAttribute("target", "_blank"); // 必須使用開啟另一視窗，才可同時下載多個檔案
    document.body.appendChild(link);
    link.click();
  });
}

async function downloadReferenceFile(urlList) {
  urlList.forEach(async (url) => {
    if (url) {
      // 檢查 url 是否已經包含 BASEURL，如果沒有則加上
      const completeUrl = url.startsWith(BASEURL) ? url : BASEURL + url;
      const response = await fetch(completeUrl);
      const blob = await response.blob();

      let filename = url.split("/")[4] || url.split("/")[3];
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    }
  });
}

async function downloadGreenhouseGasInventorySample(url) {
  const response = await fetch(BASEURL + url);
  const blob = await response.blob();

  let filename = url.split("/")[3];
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
}

function downloadDataUrl({ data, FileName }) {
  const raw = window.atob(data);
  const unit8Array = new Uint8Array(raw.length);
  for (let i = 0; i < raw.length; i++) {
    unit8Array[i] = raw.charCodeAt(i);
  }
  const link = document.createElement("a");
  const blob = new Blob([unit8Array], {
    type: "application/vnd.ms-excel",
  });
  link.href = URL.createObjectURL(blob);
  link.setAttribute("download", FileName);
  document.body.appendChild(link);
  link.click();
}

export {
  downloadExcelSample,
  downloadLCA,
  downloadReferenceFile,
  downloadGreenhouseGasInventorySample,
  downloadDataUrl,
};
