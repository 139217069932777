import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    userInfo: {},
    authorizationPages: {
      adminList: [], // 判斷報表權限 -- companyAdmin --資策會的管理者沒有這個權限
      userViewList: [], // 判斷使用者購買類型
      functionList: [], // 取得盤查計畫的 functionList
      categoryList: [],
      categoryListForAdviser: [],
    },
    companyAdmin: false,
    SiteUser: "", // III 資策會
    UserType: "",
    token:"",
  },
  reducers: {
    setUser: (state, { payload }) => {
      state.userInfo = payload;
      state.companyAdmin = payload?.IsCompanyAdmin || "";
    },
    changeUserCompany: (state, { payload }) => {
      state.userInfo = { ...state.userInfo, Company: payload };
    },
    setUserAuthorizationPages: (state, { payload }) => {
      state.authorizationPages.userViewList = payload.userViewList;
      state.authorizationPages.categoryList = payload.categoryList;
      state.authorizationPages.categoryListForAdviser =
        payload.categoryListForAdviser;
      // 如果不是公司管理者，就把產出報表權限刪除
      // 資策會的人也不會有這些權限
      if (!state.companyAdmin) {
        state.authorizationPages.adminList = [];
      } else {
        state.authorizationPages.adminList = payload.adminList;
      }
    },
    setUserType: (state, { payload }) => {
      state.UserType = payload;
      if (
        payload.toString().includes("15") ||
        payload.toString().includes("18") ||
        payload.toString().includes("19")
      ) {
        state.SiteUser = "III";
      }
    },
    setToken: (state, { payload }) => {
      state.token = payload;
    },
    setSiteUser: (state, { payload }) => {
      state.SiteUser = payload;
    },
    setAuthFunctionList: (state, { payload }) => {
      state.authorizationPages.functionList = payload;
    },
    setAuthUserViewList: (state, { payload }) => {
      state.authorizationPages.userViewList = payload;
    },
  },
});

const { reducer, actions } = userSlice;
export const {
  setUser,
  changeUserCompany,
  setUserAuthorizationPages,
  setUserType,
  setToken,
  setSiteUser,
  setAuthFunctionList,
  setAuthUserViewList,
} = actions;

export default reducer;
