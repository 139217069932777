import { useState, useEffect } from "react";
import { Button, Modal, Table } from "antd";
import { useTranslation } from "react-i18next";
import {
  // 計算碳排放總量
  GET_VEHICLE_TOTAL_API,
  GET_KITCHEN_TOTAL_API,
} from "@CON";
import axios from "axios";
import CalculateFormula from "./CalculateFormula";
import "./CalculateTotalModal.scss";

function CalculateTotalModal({ type, record }) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    let res;
    if (type === "Vehicle") {
      res = await axios.get(`${GET_VEHICLE_TOTAL_API}/${record.ID}`);
    } else if (type === "Kitchen") {
      res = await axios.get(`${GET_KITCHEN_TOTAL_API}/${record.ID}`);
    }

    console.log(res.data?.GasSummaries);
    setData(
      res.data?.GasSummaries.map((d) => ({
        ...d,
        HeatValue: res.data.HeatValue,
        Total: res.data.KgCO2e,
        Scalar: res.data.Scalar,
      }))
    );
    setLoading(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      getData();
    }
  }, [isModalOpen]);

  const columns = [
    {
      title: t("Calculate.Gas"),
      dataIndex: "GasName",
      key: "GasName",
      fixed: "left",
    },
    {
      title: t("Calculate.GasFactor"),
      dataIndex: "GasParameter",
      key: "GasParameter",
    },
    {
      title: t("Calculate.HeatValue"), // "熱值",
      dataIndex: "HeatValue",
      key: "HeatValue",
      render: (text, record, index) => {
        return index === 0
          ? {
              children: text || "0",
              props: {
                rowSpan: data.length,
              },
            }
          : {
              props: {
                rowSpan: 0,
              },
            };
      },
    },
    {
      title: t("Calculate.ParaFactor"),
      dataIndex: "Parameter",
      key: "Parameter",
    },
    {
      title: t("Process.Scalar_B"),
      dataIndex: "Scalar",
      key: "Scalar",
      render: (text, record, index) => {
        return index === 0
          ? {
              children: text || "",
              props: {
                rowSpan: data.length,
              },
            }
          : {
              props: {
                rowSpan: 0,
              },
            };
      },
    },
    {
      title: `${t("Process.Scalar_B")} x ${t("Calculate.ParaFactor")}`,
      dataIndex: "Subtotal",
      key: "Subtotal",
    },
    {
      title: "GWP",
      dataIndex: "GWP",
      key: "GWP",
    },
    {
      title: `${t("Sbti.Emission")}KgCO2e`,
      dataIndex: "GasKgCO2e",
      key: "GasKgCO2e",
    },
    {
      title: `${t("Calculate.Total_B")}KgCO2e`,
      dataIndex: "Total",
      key: "Total",
      render: (text, record, index) => {
        return index === 0
          ? {
              children: text || "",
              props: {
                rowSpan: data.length,
              },
            }
          : {
              props: {
                rowSpan: 0,
              },
            };
      },
    },
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const info = [
    "Calculate.Total.Info1",
    "Calculate.Total.Info2",
    "Calculate.Total.Info3",
  ];
  return (
    <>
      <Button type="primary" onClick={showModal}>
        {t("Calculate.Total.Button")}
      </Button>
      <Modal
        title={t("Calculate.Total.Title")}
        open={isModalOpen}
        onCancel={handleCancel}
        width={1000}
        footer={<Button onClick={handleCancel}>{t("Buttons.Cancel")}</Button>}
        className="customer"
      >
        {info.map((item) => (
          <CalculateFormula>{t(item)}</CalculateFormula>
        ))}

        <Table
          scroll={{
            x: "max-content",
          }}
          columns={columns}
          dataSource={data}
          loading={loading}
        />
      </Modal>
    </>
  );
}

export default CalculateTotalModal;
