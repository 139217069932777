import { Link } from "react-router-dom";
import { store } from "@STORE";
import { Translation, useTranslation } from "react-i18next";
import { HomeOutlined, AppstoreOutlined } from "@ant-design/icons";
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const TransGenerator = ({ to = "", text }) => {
  const { t } = useTranslation();
  return <>{to ? <Link to={to}>{t(text)}</Link> : <>{t(text)}</>}</>;
};

const navigateItemList = (functionIsShow) => [
  getItem(
    <TransGenerator to="/" text="NavBar.Home" />,
    "app",
    <HomeOutlined />
  ),
  getItem(
    <TransGenerator text="NavBar.Manage.Title" />,
    "user",
    <AppstoreOutlined />,
    [
      getItem(
        <TransGenerator
          to="/user/enterprise"
          text="NavBar.Manage.Enterprise"
        />,
        "enterprise"
      ),
      getItem(
        <TransGenerator to="/user/account" text="NavBar.Manage.Account" />,
        "account"
      ),
      functionIsShow
        ? getItem(
            <TransGenerator
              to="/user/functionList"
              text="NavBar.Manage.Function"
            />,
            "function"
          )
        : null,
    ]
  ),
];

export default navigateItemList;
