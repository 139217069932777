import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Result } from "antd";
import { useTranslation } from "react-i18next";

const ErrorBoundary = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    let data = sessionStorage.getItem("netZero");
    if(data){
    }
  }, []);

  return (
    <Result
      status="500"
      title={t("MessageText.Wrong")}
      subTitle={t("MessageText.ContactSystemAdministrator")}
      extra={
        <Button
          type="primary"
          key="console"
          onClick={() => {
            navigate(-1);
          }}
        >
          {t("Buttons.PreviousPage")}
        </Button>
      }
    />
  );
};
export default ErrorBoundary;
