import React from "react";
import { useTranslation } from "react-i18next";

export default function Privacy() {
  const { t } = useTranslation();
  return (
    <div style={{ fontSize: "18px" }}>
      <h5 className="fw-bold">{t("Privacy.Title")}</h5>
      <p>{t("Privacy.Clause1")}</p>
      <p>{t("Privacy.Clause2")}</p>
      <p>{t("Privacy.Clause3")}</p>
      <h6 className="fw-bold">{t("Privacy.Section1.Title")}</h6>
      <p>{t("Privacy.Section1.Content1")}</p>
      <p>{t("Privacy.Section1.Content2")}</p>
      <p>{t("Privacy.Section1.Content3")}</p>
      <p>{t("Privacy.Section1.Content4")}</p>
      <h6 className="fw-bold">{t("Privacy.Section2.Title")}</h6>
      <p>{t("Privacy.Section2.Content1")}</p>
      <p>
        {t("Privacy.Section2.Content2")}
        <br />
        {t("Privacy.Section2.Content3")}
        <br />
        {t("Privacy.Section2.Content4")}
        <br />
        {t("Privacy.Section2.Content5")}
        <br />
        {t("Privacy.Section2.Content6")}
      </p>
      <h6 className="fw-bold">{t("Privacy.Section3.Title")}</h6>
      <p>{t("Privacy.Section3.Content1")}</p>
      <p>
        {t("Privacy.Section3.Content2")}
        <br />
        {t("Privacy.Section3.Content3")}
        <br />
        {t("Privacy.Section3.Content4")}
        <br />
        {t("Privacy.Section3.Content5")}
        <br />
        {t("Privacy.Section3.Content6")}
        <br />
        {t("Privacy.Section3.Content7")}
        <br />
        {t("Privacy.Section3.Content8")}
        <br />
        {t("Privacy.Section3.Content9")}
        <br />
        {t("Privacy.Section3.Content10")}
        <br />
        {t("Privacy.Section3.Content11")}
        <br />
        {t("Privacy.Section3.Content12")}
        <br />
        {t("Privacy.Section3.Content13")}
        <br />
        {t("Privacy.Section3.Content14")}
        <br />
        {t("Privacy.Section3.Content15")}
        <br />
        {t("Privacy.Section3.Content16")}
        <br />
        {t("Privacy.Section3.Content17")}
        <br />
        {t("Privacy.Section3.Content18")}
      </p>
      <h6 className="fw-bold">{t("Privacy.Section4.Title")}</h6>
      <p>{t("Privacy.Section4.Content1")}</p>
      <p>
        {t("Privacy.Section4.Content2")}
        <br />
        {t("Privacy.Section4.Content3")}
        <br />
        {t("Privacy.Section4.Content4")}
        <br />
        {t("Privacy.Section4.Content5")}
        <br />
        {t("Privacy.Section4.Content6")}
      </p>
      <h6 className="fw-bold">{t("Privacy.Section5.Title")}</h6>
      <p>{t("Privacy.Section5.Content1")}</p>
      <p>
        {t("Privacy.Section5.Content2")}
        <br />
        {t("Privacy.Section5.Content3")}
        <br />
        {t("Privacy.Section5.Content4")}
        <br />
        {t("Privacy.Section5.Content5")}
      </p>
      <h6 className="fw-bold">{t("Privacy.Section6.Title")}</h6>
      <p>{t("Privacy.Section6.Content1")}</p>
      <p>{t("Privacy.Section6.Content2")}</p>
      <p>
        {t("Privacy.Section6.Content3")}
        <br />
        {t("Privacy.Section6.Content4")}
      </p>
      <p>{t("Privacy.Section6.Content5")}</p>
      <p>
        {t("Privacy.Section6.Content6")}
        <br />
        {t("Privacy.Section6.Content7")}
        <br />
        {t("Privacy.Section6.Content8")}
        <br />
        {t("Privacy.Section6.Content9")}
        <br />
        {t("Privacy.Section6.Content10")}
        <br />
        {t("Privacy.Section6.Content11")}
      </p>
      <h6 className="fw-bold">{t("Privacy.Section7.Title")}</h6>
      <p>{t("Privacy.Section7.Content1")}</p>
      <h6 className="fw-bold">{t("Privacy.Section8.Title")}</h6>
      <p>{t("Privacy.Section8.Content1")}</p>
      <p>
        {t("Privacy.Section8.Content2")}
        <br />
        {t("Privacy.Section8.Content3")}
        <br />
        {t("Privacy.Section8.Content4")}
        <br />
        {t("Privacy.Section8.Content5")}
      </p>
      <h6 className="fw-bold">{t("Privacy.Section9.Title")}</h6>
      <p>{t("Privacy.Section9.Content1")}</p>
      <h6 className="fw-bold">{t("Privacy.Section10.Title")}</h6>
      <p>{t("Privacy.Section10.Content1")}</p>
      <h6 className="fw-bold">{t("Privacy.Section11.Title")}</h6>
      <p>{t("Privacy.Section11.Content1")}</p>
      <h6 className="fw-bold">{t("Privacy.Section12.Title")}</h6>
      <p>{t("Privacy.Section12.Content1")}</p>
      <h6 className="fw-bold">{t("Privacy.Section13.Title")}</h6>
      <p>{t("Privacy.Section13.Content1")}</p>
      <p>{t("Privacy.footer")}</p>
    </div>
  );
}
