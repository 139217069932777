import React, { useEffect, useState, useRef, useContext } from "react";
import { Button, Modal, Form, Input } from "antd";
import {
  APIProvider,
  ControlPosition,
  Map,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import { FormContext } from "./CalculateGoogleMap";
import { GoogleMapPlaceContext } from "./CalculateGoogleMapModal";

function CalculateGoogleMapForm({ setSelectedPlace }) {
  const GoogleMapContext = useContext(GoogleMapPlaceContext);

  // get form pos
  const ori_places = useMapsLibrary("places");
  const des_places = useMapsLibrary("places");
  const originRef = useRef(null);
  const [originAutocomplete, setOriginAutocomplete] = useState(null);
  const destinationRef = useRef(null);
  const [destinationAutocomplete, setDestinationAutocomplete] = useState(null);

  useEffect(() => {
    if (!ori_places || !originRef.current) return;

    const options = {
      fields: ["geometry", "name", "formatted_address"],
    };

    setOriginAutocomplete(
      new ori_places.Autocomplete(originRef.current, options)
    );
  }, [ori_places]);

  useEffect(() => {
    if (!des_places || !destinationRef.current) return;

    const options = {
      fields: ["geometry", "name", "formatted_address"],
    };

    setDestinationAutocomplete(
      new des_places.Autocomplete(destinationRef.current, options)
    );
  }, [des_places]);

  useEffect(() => {
    if (!originAutocomplete) return;

    originAutocomplete.addListener("place_changed", () => {
      setSelectedPlace((prev) => ({
        ...prev,
        ori: originAutocomplete.getPlace(),
      }));
    });
  }, [setSelectedPlace, originAutocomplete]);

  useEffect(() => {
    if (!destinationAutocomplete) return;

    destinationAutocomplete.addListener("place_changed", () => {
      setSelectedPlace((prev) => ({
        ...prev,
        des: destinationAutocomplete.getPlace(),
      }));
    });
  }, [setSelectedPlace, destinationAutocomplete]);

  return (
    <>
      <div class="d-flex align-items-center">
        <label for="origin" className="">
          起點:
        </label>
        <div className="">
          <input
            type="text"
            ref={originRef}
            className="form-control"
            id="origin"
            name="ori"
            placeholder={
              GoogleMapContext.selectedPlace.ori?.formatted_address ??
              "請輸入起點"
            }
          />
        </div>
      </div>
      <div class="my-3 d-flex  align-items-center">
        <label for="destination" className="">
          終點:
        </label>
        <div className="">
          <input
            type="text"
            ref={destinationRef}
            className="form-control"
            id="destination"
            name="des"
            placeholder={
              GoogleMapContext.selectedPlace.des?.formatted_address ??
              "請輸入終點"
            }
          />
        </div>
      </div>
    </>
  );
}

export default CalculateGoogleMapForm;
