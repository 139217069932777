//新增公司係數
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ProFormDigit,
  ProFormUploadButton,
  ProFormSelect,
  ProFormText,
  ProFormDependency,
  ProCard,
} from "@ant-design/pro-components";
import {
  FileSearchOutlined,
  EditOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Cascader,
  Input,
  InputNumber,
  Space,
  Select,
  Divider,
  Table,
  Modal,
} from "antd";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { formatScalar, formatKgCO2e, findAreaAndYear } from "@UTIL";
import CalculateAddParaFormTabs from "@COM/project/calculate/CalculateAddParaFormTabs";
import {
  GET_SOURCE_PARA_BYID_API,
  BASEURL,
  Delete_CompanySourceRefereneceFileLinks,
} from "@CON";

const { TextArea } = Input;

const FORC1_ADD_FORM = ({
  CompanyAreaList,
  CompanyTypeList,
  setAreaModalIsOpen,
  setTypeModalIsOpen,
  setEditData,
  form,
  deleteAreaItem,
  deleteTypeItem,
  editRecord,
  setResetChildState,
  ...props
}) => {
  const { t } = useTranslation();
  const conversionFactor = 4.1868e-9;
  const coefficientColumnsGHG = [
    {
      title: "GHG",
      dataIndex: "version", // 用來顯示GHG類型（例如CO2、N2O、CH4）
      key: "version",
      width: "25%",
      align: "center",
      render: (text) => ({
        props: {
          style: { background: "#fafafa" }, // 背景顏色設定
        },
        children: <div>{text}</div>,
      }),
    },
    {
      title: t("CompanyPara.GHGfactor"),
      dataIndex: "value", // 這裡的dataIndex指向的是每行的value屬性
      key: "value",
      align: "center",
    },
  ];

  const initialCoefficientDataGHG = [
    {
      key: "CO2Value", // 將 key 設為 CO2Value
      version: "CO2", // 這是GHG類型顯示的名稱
      value: "", // 對應的值
    },
    {
      key: "N2OValue", // 將 key 設為 N2OValue
      version: "N2O",
      value: "",
    },
    {
      key: "CH4Value", // 將 key 設為 CH4Value
      version: "CH4",
      value: "",
    },
  ];

  const coefficientColumns = [
    {
      title: "IPCC Version",
      dataIndex: "version",
      key: "version",
      width: "25%",
      align: "center",
      render: (text) => ({
        props: {
          style: { background: "#fafafa" }, // 背景顏色設定
        },
        children: <div>{text}</div>,
      }),
    },
    {
      title: t("CompanyPara.CompositeFactor"),
      dataIndex: "value",
      key: "value",
      align: "center",
    },
  ];
  const initialCoefficientData = [
    {
      key: "AR4Value",
      version: "AR4",
      value: "",
    },
    {
      key: "AR5Value",
      version: "AR5",
      value: "",
    },
    {
      key: "AR6Value",
      version: "AR6",
      value: "",
    },
    {
      key: "AR6_v2024Value",
      version: "AR6-2024",
      value: "",
    },
  ];
  const [coefficientDataGHG, setCoefficientDataGHG] = useState(
    initialCoefficientDataGHG
  );
  const [coefficientData, setCoefficientData] = useState(
    initialCoefficientData
  );
  const [assessmentReportGWPs, setAssessmentReportGWPs] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleTypeSelect = (TypeID) => {
    axios
      .get(`${GET_SOURCE_PARA_BYID_API}/${TypeID}`)
      .then((response) => {
        const data = response.data;

        const IPCC2006CO2 = data.targetYearlyParameterType?.IPCC2006CO2;
        const IPCC2006N2O = data.targetYearlyParameterType?.IPCC2006N2O;
        const IPCC2006CH4 = data.targetYearlyParameterType?.IPCC2006CH4;
        const OriHeatValue = data.targetYearlyParameterType?.OriHeatValue;
        const gwps = data.assessmentReportGWPs; // 從API中獲取GWP數據

        if (
          IPCC2006CO2 !== undefined ||
          IPCC2006N2O !== undefined ||
          IPCC2006CH4 !== undefined ||
          OriHeatValue !== undefined
        ) {
          if (form && form.setFieldsValue) {
            form.setFieldsValue({
              CustomCO2: IPCC2006CO2,
              CustomN2O: IPCC2006N2O,
              CustomCH4: IPCC2006CH4,
              CustomHeatValue: OriHeatValue,
            });
            console.log(form.getFieldsValue());

            // 存儲 assessmentReportGWPs 到狀態
            setAssessmentReportGWPs(gwps);

            // 執行companyParaGHG函數
            companyParaGHG(OriHeatValue, IPCC2006CO2, IPCC2006N2O, IPCC2006CH4);

            // 執行companyParaCoefficient函數
            if (gwps) {
              companyParaCoefficient(
                OriHeatValue,
                IPCC2006CO2,
                IPCC2006N2O,
                IPCC2006CH4,
                gwps
              );
            } else {
              console.error("assessmentReportGWPs is undefined.");
            }
          } else {
            console.error(
              "form is not defined or form.setFieldsValue is not a function"
            );
          }
        } else {
          console.log(
            "IPCC2006CO2, IPCC2006N2O, or IPCC2006CH4 is not present in the targetYearlyParameterType."
          );
        }
      })
      .catch((error) => {
        console.error("讀取失敗:", error);
      });
  };

  const handleCalculateClick = () => {
    // 假設這些變數是從表單或其他來源取得
    const formValues = form.getFieldsValue(); // 假設form是已定義的
    const OriHeatValue = formValues.CustomHeatValue;
    const IPCC2006CO2 = formValues.CustomCO2;
    const IPCC2006N2O = formValues.CustomN2O;
    const IPCC2006CH4 = formValues.CustomCH4;
    const TypeID = formValues.TypeID;

    let missingData = [];

    if (
      OriHeatValue === undefined ||
      OriHeatValue === 0 ||
      OriHeatValue === null
    )
      missingData.push(t("CompanyPara.LowerHeatingValue"));
    if (IPCC2006CO2 === undefined || IPCC2006CO2 === null)
      missingData.push(t("CompanyPara.CO2"));
    if (IPCC2006N2O === undefined || IPCC2006N2O === null)
      missingData.push(t("CompanyPara.N2O"));
    if (IPCC2006CH4 === undefined || IPCC2006CH4 === null)
      missingData.push(t("CompanyPara.CH4"));

    if (missingData.length > 0) {
      const errorMessage = `${t("CompanyPara.Missing")}: ${missingData.join(
        ", "
      )}`;
      console.error(errorMessage);
      setErrorMessage(errorMessage);
    } else {
      // 只取GWP
      axios
        .get(`${GET_SOURCE_PARA_BYID_API}/${TypeID}`)
        .then((response) => {
          const data = response.data;
          const gwps = data.assessmentReportGWPs;

          if (gwps) {
            companyParaGHG(OriHeatValue, IPCC2006CO2, IPCC2006N2O, IPCC2006CH4);
            companyParaCoefficient(
              OriHeatValue,
              IPCC2006CO2,
              IPCC2006N2O,
              IPCC2006CH4,
              gwps
            );
            setErrorMessage(""); // 清除錯誤訊息
          } else {
            console.error("Cannot get assessmentReportGWP");
            setErrorMessage("Cannot get assessmentReportGWP");
          }
        })
        .catch((error) => {
          console.error("API 讀取失敗:", error);
          setErrorMessage("無法讀取 API");
        });
    }
  };

  const companyParaGHG = (
    OriHeatValue,
    IPCC2006CO2,
    IPCC2006N2O,
    IPCC2006CH4
  ) => {
    const updatedCoefficientDataGHG = coefficientDataGHG.map((item) => {
      let newValue = item.value;
      switch (item.version) {
        case "CO2":
          newValue = OriHeatValue * conversionFactor * IPCC2006CO2;
          break;
        case "N2O":
          newValue = OriHeatValue * conversionFactor * IPCC2006N2O;
          break;
        case "CH4":
          newValue = OriHeatValue * conversionFactor * IPCC2006CH4;
          break;
        default:
          break;
      }
      return { ...item, value: newValue.toFixed(10) };
    });

    setCoefficientDataGHG(updatedCoefficientDataGHG);
  };

  const companyParaCoefficient = (
    OriHeatValue,
    IPCC2006CO2,
    IPCC2006N2O,
    IPCC2006CH4,
    assessmentReportGWPs
  ) => {
    const updatedCoefficientData = coefficientData.map((item) => {
      const matchedGWP = assessmentReportGWPs.find((gwp) => {
        return (
          gwp.ARversion === item.version ||
          (item.version === "AR6-2024" && gwp.ARversion === "AR6_v2024")
        );
      });

      let newValue = 0;
      if (matchedGWP) {
        const { CO2, CH4, N2O } = matchedGWP.GasValuePair;

        const CustomCO2 = parseFloat(
          (OriHeatValue * conversionFactor * IPCC2006CO2 * CO2).toFixed(10)
        );
        const CustomCH4 = parseFloat(
          (OriHeatValue * conversionFactor * IPCC2006CH4 * CH4).toFixed(10)
        );
        const CustomN2O = parseFloat(
          (OriHeatValue * conversionFactor * IPCC2006N2O * N2O).toFixed(10)
        );

        newValue = CustomCO2 + CustomCH4 + CustomN2O;
      }

      return { ...item, value: newValue.toFixed(10) };
    });

    setCoefficientData(updatedCoefficientData);
  };

  // 定義重置函數
  const resetCoefficients = () => {
    setCoefficientDataGHG(initialCoefficientDataGHG);
    setCoefficientData(initialCoefficientData);
    setErrorMessage(""); // 重置錯誤訊息
  };

  const [fileList, setFileList] = useState([]);
  useEffect(() => {
    if (editRecord?.SourceRefereneceFileLinks) {
      const initialFiles = editRecord.SourceRefereneceFileLinks.filter(
        (item) => item.RefereneceFileLink
      ).map((item) => ({
        uid: item.ID, // 使用 ID 作為唯一識別符
        name: item.RefereneceFileLink.split("/").pop(), // 提取文件名
        status: "done", // 設定狀態為已完成
        url: BASEURL + item.RefereneceFileLink, // 文件的 URL
      }));
      setFileList(initialFiles); // 初始化文件列表
    } else {
      setFileList([]);
    }
  }, [editRecord]);

  const handleFileChange = ({ file, fileList: newFileList }) => {
    // 處理新增文件邏輯
    if (file.status !== "removed") {
      setFileList(newFileList); // 更新文件列表
      form?.setFieldValue("SourceRefereneceFileLinks", newFileList);
    }
  };

  const handleFileRemove = (file) => {
    Modal.confirm({
      title: t("MessageText.Delete Item"), // 確認對話框的標題
      content: t("MessageText.Delete Confirmed"), // 確認對話框的內容
      okText: t("Buttons.Confirm"), // 確認按鈕的文本
      cancelText: t("Buttons.Cancel"), // 取消按鈕的文本
      onOk: () => {
        console.log("Removing file with uid:", file.uid);

        // 發送 DELETE 請求到 API，使用 file.uid 作為 sourceRefereneceFileLinksID
        axios
          .delete(`${Delete_CompanySourceRefereneceFileLinks}/${file.uid}`)
          .then((response) => {
            console.log("File deleted successfully:", response.data);

            // 刪除成功後，過濾掉被移除的文件，根據文件的 uid 來比對
            const updatedFileList = fileList.filter(
              (item) => item.uid !== file.uid
            );

            // 更新文件列表狀態
            setFileList(updatedFileList);

            // 更新表單的 "SourceRefereneceFileLinks" 欄位值
            form?.setFieldValue("SourceRefereneceFileLinks", updatedFileList);
          })
          .catch((error) => {
            console.error("Error deleting file:", error);
          });
      },
    });
  };

  // 使用 useEffect 傳遞重置函數給父層
  useEffect(() => {
    if (setResetChildState) {
      setResetChildState(() => resetCoefficients);
    }
  }, [setResetChildState]);

  useEffect(() => {
    if (editRecord) {
      handleCalculateClick(); // 當editRecord有值時，自動執行計算
    }
  }, [editRecord]); // 當editRecord變化時觸發這個useEffect

  return [
    {
      label: "地區",
      key: "AreaID",
      Node: (
        <div className="row">
          <div className="col-3 text-end">
            <label htmlFor="AreaID">
              <span className="text-danger">*</span> {t("CompanyPara.Area")} :
            </label>
          </div>
          <div className="col-6">
            <Form.Item
              name="AreaID"
              className="w-100"
              style={{ marginBottom: 0 }}
            >
              <Select
                optionLabelProp="label"
                disabled={editRecord?.AreaID ? true : false} // 如果editRecord的AreaID有值則鎖定
                style={{ maxWidth: "410px", width: "100%" }}
              >
                {CompanyAreaList.map((Area) => (
                  <Select.Option
                    value={Area.ID}
                    key={Area.ID}
                    title={Area.DisplayName}
                    label={Area.DisplayName}
                    style={{ whiteSpace: "normal" }}
                  >
                    <span
                      className="d-flex justify-content-between"
                      style={{ flexWrap: "wrap" }}
                    >
                      <span style={{ wordBreak: "break-word" }}>
                        {Area.DisplayName}
                      </span>
                      <span>
                        {Area.CompanyID && (
                          <>
                            <EditOutlined
                              className="me-3"
                              onClick={() => {
                                setAreaModalIsOpen(true);
                                setTypeModalIsOpen(false);
                                setEditData(Area);
                              }}
                            />
                            <DeleteOutlined
                              className="text-danger"
                              onClick={(e) => {
                                e.stopPropagation(); // 確保事件不被阻止
                                deleteAreaItem(Area.ID);
                              }}
                            />
                          </>
                        )}
                      </span>
                    </span>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="col-3">
            <Form.Item>
              <Button
                key="add"
                type="primary"
                className="bg-success"
                onClick={() => {
                  setAreaModalIsOpen(true);
                  setTypeModalIsOpen(false);
                }}
                style={{
                  visibility: editRecord?.AreaID ? "hidden" : "visible", // 如果已有資料則隱藏按鈕
                }}
              >
                {t("Buttons.Add")}
              </Button>
            </Form.Item>
          </div>
        </div>
      ),
    },
    {
      label: "排放源",
      key: "TypeID",
      Node: (
        <div className="row">
          <div className="col-3 text-end">
            <label htmlFor="TypeID">
              <span className="text-danger">*</span>{" "}
              {t("CalculateForm.ParameterID")} :
            </label>
          </div>
          <div className="col-6">
            <Form.Item
              name="TypeID"
              className="w-100"
              style={{ marginBottom: 0 }}
            >
              <Select
                optionLabelProp="label"
                onChange={(value) => handleTypeSelect(value, form)}
                disabled={editRecord?.TypeID ? true : false} // 如果editRecord的TypeID有值則鎖定
                style={{ maxWidth: "410px", width: "100%" }}
              >
                {CompanyTypeList.map((Type) => (
                  <Select.Option
                    value={Type.ID}
                    key={Type.ID}
                    title={Type.DisplayName}
                    label={Type.DisplayName}
                    style={{ whiteSpace: "normal" }}
                  >
                    <span
                      className="d-flex justify-content-between"
                      style={{ flexWrap: "wrap" }}
                    >
                      <span style={{ wordBreak: "break-word" }}>
                        {Type.DisplayName}
                      </span>
                      <span>
                        {Type.CompanyID && (
                          <>
                            <EditOutlined
                              className="me-3"
                              onClick={() => {
                                setTypeModalIsOpen(true);
                                setAreaModalIsOpen(false);
                                setEditData(Type);
                              }}
                            />
                            <DeleteOutlined
                              className="text-danger"
                              onClick={(e) => {
                                e.stopPropagation(); // 確保事件不被阻止
                                deleteTypeItem(Type.ID);
                              }}
                            />
                          </>
                        )}
                      </span>
                    </span>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="col-3">
            <Form.Item>
              <Button
                key="add"
                type="primary"
                className="bg-success"
                onClick={() => {
                  setTypeModalIsOpen(true);
                  setAreaModalIsOpen(false);
                }}
                style={{
                  visibility: editRecord?.TypeID ? "hidden" : "visible", // 如果已有資料則隱藏按鈕
                }}
              >
                {t("Buttons.Add")}
              </Button>
            </Form.Item>
          </div>
        </div>
      ),
    },
    {
      label: "年份",
      key: "Year",
      Node: (
        <div className="row">
          <div className="col-3 text-end">
            <label htmlFor="Year">
              <span className="text-danger">*</span> {t("CompanyPara.Year")} :
            </label>
          </div>
          <div className="col-6">
            <Form.Item
              name="Year"
              rules={[
                {
                  required: true,
                },
              ]}
              className="w-100"
            >
              <Select
                disabled={editRecord?.Year ? true : false} // 如果editRecord的Year有值則鎖定
              >
                {Array.from(
                  { length: 8 },
                  (_, i) => new Date().getFullYear() + 1 - i
                ).map((year) => (
                  <Select.Option value={year} key={year}>
                    <span className="d-flex justify-content-between">
                      {year}
                    </span>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>
      ),
    },
    {
      label: "CO2",
      key: "CustomCO2",
      Node: (
        <div className="row">
          <div className="col-3 text-end">
            <label htmlFor="CustomCO2">
              <span className="text-danger">*</span> {t("CompanyPara.CO2")} :
            </label>
          </div>
          <div className="col-6">
            <Form.Item
              name="CustomCO2"
              rules={[
                {
                  required: true,
                  message: `${t("CompanyPara.Plz")}${t("CompanyPara.CO2")}`,
                },
              ]}
            >
              <InputNumber
                className="w-100 text-end"
                min={0}
                placeholder={t("CompanyPara.CO2")}
              />
            </Form.Item>
          </div>
          <div className="col-3 text-start">
            <span>kgCO2/TJ</span>
          </div>
        </div>
      ),
    },
    {
      label: "N2O",
      key: "CustomN2O",
      Node: (
        <div className="row">
          <div className="col-3 text-end">
            <label htmlFor="CustomN2O">
              <span className="text-danger">*</span> {t("CompanyPara.N2O")} :
            </label>
          </div>
          <div className="col-6">
            <Form.Item
              name="CustomN2O"
              rules={[
                {
                  required: true,
                  message: `${t("CompanyPara.Plz")}${t("CompanyPara.N2O")}`,
                },
              ]}
            >
              <InputNumber
                className="w-100 text-end"
                min={0}
                placeholder={t("CompanyPara.N2O")}
              />
            </Form.Item>
          </div>
          <div className="col-3 text-start">
            <span>kgN2O/TJ</span>
          </div>
        </div>
      ),
    },
    {
      label: "CH4",
      key: "CustomCH4",
      Node: (
        <div className="row">
          <div className="col-3 text-end">
            <label htmlFor="CustomCH4">
              <span className="text-danger">*</span> {t("CompanyPara.CH4")} :
            </label>
          </div>
          <div className="col-6">
            <Form.Item
              name="CustomCH4"
              rules={[
                {
                  required: true,
                  message: `${t("CompanyPara.Plz")}${t("CompanyPara.CH4")}`,
                },
              ]}
            >
              <InputNumber
                className="w-100 text-end"
                min={0}
                placeholder={t("CompanyPara.CH4")}
              />
            </Form.Item>
          </div>
          <div className="col-3 text-start">
            <span>kgCH4/TJ</span>
          </div>
        </div>
      ),
    },
    {
      label: "低位熱值",
      key: "CustomHeatValue",
      Node: (
        <div className="row">
          <div className="col-3 text-end">
            <label htmlFor="LowerHeatingValue">
              <span className="text-danger">*</span>{" "}
              {t("CompanyPara.LowerHeatingValue")} :
            </label>
          </div>
          <div className="col-6">
            <Form.Item
              name="CustomHeatValue"
              rules={[
                {
                  required: true,
                  message: `${t("CompanyPara.Plz")}${t(
                    "CompanyPara.LowerHeatingValue"
                  )}`,
                },
              ]}
            >
              <InputNumber
                className="w-100 text-end"
                min={0}
                placeholder={t("CompanyPara.LowerHeatingValue")}
              />
            </Form.Item>
          </div>
          <div className="col-3 text-start">
            <span>kcal/L or kcal/m³ or kcal/kg</span>
          </div>
        </div>
      ),
    },
    {
      label: "上游係數",
      key: "UpStream",
      Node: (
        <div className="row">
          <div className="col-3 text-end">
            <label htmlFor="UpStream">{t("CompanyPara.UpStream")} :</label>
          </div>
          <div className="col-6">
            <Form.Item name="UpStream" extra={t("CompanyPara.UpStreamFactor")}>
              <InputNumber className="w-100 text-end" min={0} />
            </Form.Item>
          </div>
        </div>
      ),
    },
    {
      label: "計算",
      key: "Calculate",
      Node: (
        <div className="row">
          <div className="d-flex justify-content-center">
            <Form.Item>
              <Button
                key="add"
                type="primary"
                className="bg-success"
                onClick={handleCalculateClick}
              >
                {t("Buttons.Calculate")}
              </Button>
            </Form.Item>
          </div>
          {errorMessage && (
            <div
              className="error-message text-center"
              style={{ color: "red", marginTop: "10px" }}
            >
              {errorMessage}
            </div>
          )}
        </div>
      ),
    },
    {
      label: "計算公式1",
      key: "CalculationFormula1",
      Node: (
        <div className="row justify-content-center mt-3 mb-5">
          {t("CompanyPara.CalculationFormula1")}
        </div>
      ),
    },
    {
      label: "各GHG排放係數",
      key: "CoefficientTable",
      Node: (
        <div className="row">
          <div className="col-3 text-end">
            <label htmlFor="CoefficientTable">{t("CompanyPara.GHG")} :</label>
          </div>
          <div className="col-7">
            <Form.Item name="CoefficientTable">
              <Table
                columns={coefficientColumnsGHG}
                dataSource={coefficientDataGHG}
                size="small"
                pagination={false}
                bordered
                className="text-center"
              />
            </Form.Item>
          </div>
        </div>
      ),
    },
    {
      label: "綜合係數",
      key: "CoefficientTable",
      Node: (
        <div className="row">
          <div className="col-2 text-end">
            <label htmlFor="CoefficientTable">
              {t("CompanyPara.Coefficient")} :
            </label>
          </div>
          <div className="col-9">
            <Form.Item name="CoefficientTable">
              <Table
                columns={coefficientColumns}
                dataSource={coefficientData}
                size="small"
                pagination={false}
                bordered
                rowKey="key"
                className="text-center"
              />
            </Form.Item>
          </div>
        </div>
      ),
    },
    {
      label: "計算公式2",
      key: "CalculationFormula2",
      Node: (
        <div className="row justify-content-center mb-5">
          {t("CompanyPara.CalculationFormula2")}
        </div>
      ),
    },
    {
      label: "說明",
      key: "Description",
      Node: (
        <div className="row">
          <div className="col-2 text-end">
            <label htmlFor="Description">
              {t("CompanyPara.Description")} :
            </label>
          </div>
          <div className="col-9">
            <Form.Item name="Description">
              <TextArea />
            </Form.Item>
          </div>
        </div>
      ),
    },
    {
      label: "資料來源名稱",
      key: "DisplayNameTW",
      Node: (
        <div className="row">
          <div className="col-3 text-end">
            <label htmlFor="Description">
              {t("CalculateForm.referenceFileName")} :
            </label>
          </div>
          <div className="col-8">
            <ProFormText
              key={uuidv4()}
              colProps={{ md: 8 }}
              name="DisplayNameTW"
            />
          </div>
        </div>
      ),
    },
    {
      label: "佐證資料上傳",
      key: "referenceFile",
      Node: (
        <div className="row">
          <div className="col-3 text-end">
            <label htmlFor="referenceFile">
              {t("CalculateForm.referenceFile")} :
            </label>
          </div>
          <div className="col-8 text-end">
            <ProFormUploadButton
              key={uuidv4()}
              colProps={{ md: 6 }}
              name="referenceFile"
              title={t("Buttons.Upload")}
              fieldProps={{
                multiple: true,
                fileList: fileList,
                onChange: handleFileChange,
                onRemove: handleFileRemove,
                beforeUpload: () => false, // 阻止文件上傳到伺服器
              }}
            />
          </div>
        </div>
      ),
    },
  ];
};

// 這裡是一般電力的新增公司係數表單
const POWERUSAGES_ADD_FORM = ({
  CompanyAreaList,
  CompanyTypeList,
  setAreaModalIsOpen,
  setTypeModalIsOpen,
  setEditData,
  form,
  deleteAreaItem,
  deleteTypeItem,
  editRecord,
  setResetChildState,
  ...props
}) => {
  const { t } = useTranslation();
  const [customElectricValue, setCustomElectricValue] = useState(0);

  const coefficientColumns = [
    {
      title: "IPCC Version",
      dataIndex: "version",
      key: "version",
      width: "25%",
      align: "center",
      render: (text) => ({
        props: {
          style: { background: "#fafafa" }, // 背景顏色設定
        },
        children: <div>{text}</div>,
      }),
    },
    {
      title: t("CompanyPara.CompositeFactor"),
      dataIndex: "value",
      key: "value",
      align: "center",
    },
  ];
  const initialCoefficientData = [
    {
      key: "AR4Value",
      version: "AR4",
      value: "",
    },
    {
      key: "AR5Value",
      version: "AR5",
      value: "",
    },
    {
      key: "AR6Value",
      version: "AR6",
      value: "",
    },
    {
      key: "AR6_v2024Value",
      version: "AR6-2024",
      value: "",
    },
  ];
  const [coefficientData, setCoefficientData] = useState(
    initialCoefficientData
  );
  const [errorMessage, setErrorMessage] = useState("");

  const handleTypeSelect = (TypeID) => {
    axios
      .get(`${GET_SOURCE_PARA_BYID_API}/${TypeID}`)
      .then((response) => {
        const data = response.data;

        const IPCC2006CO2 = data.targetYearlyParameterType?.IPCC2006CO2;
        const IPCC2006N2O = data.targetYearlyParameterType?.IPCC2006N2O;
        const IPCC2006CH4 = data.targetYearlyParameterType?.IPCC2006CH4;
        const OriHeatValue = data.targetYearlyParameterType?.OriHeatValue;
        const gwps = data.assessmentReportGWPs; // 從API中獲取GWP數據

        if (
          IPCC2006CO2 !== undefined ||
          IPCC2006N2O !== undefined ||
          IPCC2006CH4 !== undefined ||
          OriHeatValue !== undefined
        ) {
          if (form && form.setFieldsValue) {
            form.setFieldsValue({
              CustomCO2: IPCC2006CO2,
              CustomN2O: IPCC2006N2O,
              CustomCH4: IPCC2006CH4,
              CustomHeatValue: OriHeatValue,
            });
          } else {
            console.error(
              "form is not defined or form.setFieldsValue is not a function"
            );
          }
        } else {
          console.log(
            "IPCC2006CO2, IPCC2006N2O, or IPCC2006CH4 is not present in the targetYearlyParameterType."
          );
        }
      })
      .catch((error) => {
        console.error("讀取失敗:", error);
      });
  };

  // 定義重置函數
  const resetCoefficients = () => {
    setCoefficientData(initialCoefficientData);
    setErrorMessage(""); // 重置錯誤訊息
  };

  // 定義上傳文件函數
  const [fileList, setFileList] = useState([]);
  useEffect(() => {
    if (editRecord?.SourceRefereneceFileLinks) {
      const initialFiles = editRecord.SourceRefereneceFileLinks.filter(
        (item) => item.RefereneceFileLink
      ).map((item) => ({
        uid: item.ID, // 使用 ID 作為唯一識別符
        name: item.RefereneceFileLink.split("/").pop(), // 提取文件名
        status: "done", // 設定狀態為已完成
        url: BASEURL + item.RefereneceFileLink, // 文件的 URL
      }));
      setFileList(initialFiles); // 初始化文件列表
    } else {
      setFileList([]);
    }
  }, [editRecord]);
  const handleFileChange = ({ file, fileList: newFileList }) => {
    // 處理新增文件邏輯
    if (file.status !== "removed") {
      setFileList(newFileList); // 更新文件列表
      form?.setFieldValue("SourceRefereneceFileLinks", newFileList);
    }
  };
  const handleFileRemove = (file) => {
    Modal.confirm({
      title: t("MessageText.Delete Item"), // 確認對話框的標題
      content: t("MessageText.Delete Confirmed"), // 確認對話框的內容
      okText: t("Buttons.Confirm"), // 確認按鈕的文本
      cancelText: t("Buttons.Cancel"), // 取消按鈕的文本
      onOk: () => {
        console.log("Removing file with uid:", file.uid);

        // 發送 DELETE 請求到 API，使用 file.uid 作為 sourceRefereneceFileLinksID
        axios
          .delete(`${Delete_CompanySourceRefereneceFileLinks}/${file.uid}`)
          .then((response) => {
            console.log("File deleted successfully:", response.data);

            // 刪除成功後，過濾掉被移除的文件，根據文件的 uid 來比對
            const updatedFileList = fileList.filter(
              (item) => item.uid !== file.uid
            );

            // 更新文件列表狀態
            setFileList(updatedFileList);

            // 更新表單的 "SourceRefereneceFileLinks" 欄位值
            form?.setFieldValue("SourceRefereneceFileLinks", updatedFileList);
          })
          .catch((error) => {
            console.error("Error deleting file:", error);
          });
      },
    });
  };

  const handleCustomElectricValueChange = (value) => {
    setCustomElectricValue(value);
    // 更新 coefficientData 的 value 屬性
    const updatedData = coefficientData.map((item) => ({
      ...item,
      value: value, // 根據 CustomElectricValue 更新每一行的值
    }));
    setCoefficientData(updatedData); // 更新 coefficientData 狀態
  };

  // 使用 useEffect 傳遞重置函數給父層
  useEffect(() => {
    if (setResetChildState) {
      setResetChildState(() => resetCoefficients);
    }
  }, [setResetChildState]);

  // 監聽 editRecord 變化帶入 customElectricValue
  useEffect(() => {
    if (editRecord && editRecord.CustomElectricValue) {
      let customElectricValue = [];
      // 將 editRecord.CustomElectricValue 處理為陣列
      if (Array.isArray(editRecord.CustomElectricValue)) {
        customElectricValue = editRecord.CustomElectricValue;
      } else if (typeof editRecord.CustomElectricValue === "number") {
        customElectricValue = [editRecord.CustomElectricValue]; // 單一數值轉為陣列
      }
      // 確保 customElectricValue 能覆蓋所有欄位
      const updatedCoefficientData = initialCoefficientData.map((item) => ({
        ...item,
        value: customElectricValue, // 根據 CustomElectricValue 更新每一行的值
      }));
      setCoefficientData(updatedCoefficientData); // 更新 coefficientData 狀態
    }
    // 確保只渲染一次
  }, [editRecord]); // 監聽 editRecord 變化，只會執行一次

  return [
    {
      label: "地區",
      key: "AreaID",
      Node: (
        <div className="row">
          <div className="col-3 text-end">
            <label htmlFor="AreaID">
              <span className="text-danger">*</span> {t("CompanyPara.Area")} :
            </label>
          </div>
          <div className="col-6">
            <Form.Item
              name="AreaID"
              className="w-100"
              style={{ marginBottom: 0 }}
            >
              <Select
                optionLabelProp="label"
                disabled={editRecord?.AreaID ? true : false} // 如果editRecord的AreaID有值則鎖定
                style={{ maxWidth: "410px", width: "100%" }}
              >
                {CompanyAreaList.map((Area) => (
                  <Select.Option
                    value={Area.ID}
                    key={Area.ID}
                    title={Area.DisplayName}
                    label={Area.DisplayName}
                    style={{ whiteSpace: "normal" }}
                  >
                    <span
                      className="d-flex justify-content-between"
                      style={{ flexWrap: "wrap" }}
                    >
                      <span style={{ wordBreak: "break-word" }}>
                        {Area.DisplayName}
                      </span>
                      <span>
                        {Area.CompanyID && (
                          <>
                            <EditOutlined
                              className="me-3"
                              onClick={() => {
                                setAreaModalIsOpen(true);
                                setTypeModalIsOpen(false);
                                setEditData(Area);
                              }}
                            />
                            <DeleteOutlined
                              className="text-danger"
                              onClick={(e) => {
                                e.stopPropagation(); // 確保事件不被阻止
                                deleteAreaItem(Area.ID);
                              }}
                            />
                          </>
                        )}
                      </span>
                    </span>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="col-3">
            <Form.Item>
              <Button
                key="add"
                type="primary"
                className="bg-success"
                onClick={() => {
                  setAreaModalIsOpen(true);
                  setTypeModalIsOpen(false);
                }}
                style={{
                  visibility: editRecord?.AreaID ? "hidden" : "visible", // 如果已有資料則隱藏按鈕
                }}
              >
                {t("Buttons.Add")}
              </Button>
            </Form.Item>
          </div>
        </div>
      ),
    },
    {
      label: "排放源",
      key: "TypeID",
      Node: (
        <div className="row">
          <div className="col-3 text-end">
            <label htmlFor="TypeID">
              <span className="text-danger">*</span>{" "}
              {t("CalculateForm.ParameterID")} :
            </label>
          </div>
          <div className="col-6">
            <Form.Item
              name="TypeID"
              className="w-100"
              style={{ marginBottom: 0 }}
              initialValue={1} // 設定 TypeID 初始值為 1
            >
              <Select
                optionLabelProp="label"
                onChange={(value) => handleTypeSelect(value, form)}
                value={1} // 預設選擇Type.ID為1
                disabled={true} // 永久鎖定
                style={{ maxWidth: "410px", width: "100%" }}
              >
                {CompanyTypeList.map((Type) => (
                  <Select.Option
                    value={Type.ID}
                    key={Type.ID}
                    title={Type.DisplayName}
                    label={Type.DisplayName}
                    style={{ whiteSpace: "normal" }}
                  >
                    <span
                      className="d-flex justify-content-between"
                      style={{ flexWrap: "wrap" }}
                    >
                      <span style={{ wordBreak: "break-word" }}>
                        {Type.DisplayName}
                      </span>
                      <span>
                        {Type.CompanyID && (
                          <>
                            <EditOutlined
                              className="me-3"
                              onClick={() => {
                                setTypeModalIsOpen(true);
                                setAreaModalIsOpen(false);
                                setEditData(Type);
                              }}
                            />
                            <DeleteOutlined
                              className="text-danger"
                              onClick={(e) => {
                                e.stopPropagation(); // 確保事件不被阻止
                                deleteTypeItem(Type.ID);
                              }}
                            />
                          </>
                        )}
                      </span>
                    </span>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="col-3">
            <Form.Item>
              <Button
                key="add"
                type="primary"
                className="bg-success"
                onClick={() => {
                  setTypeModalIsOpen(true);
                  setAreaModalIsOpen(false);
                }}
                style={{
                  visibility: "hidden", // 如果已有資料則隱藏按鈕
                }}
              >
                {t("Buttons.Add")}
              </Button>
            </Form.Item>
          </div>
        </div>
      ),
    },
    {
      label: "年份",
      key: "Year",
      Node: (
        <div className="row">
          <div className="col-3 text-end">
            <label htmlFor="Year">
              <span className="text-danger">*</span> {t("CompanyPara.Year")} :
            </label>
          </div>
          <div className="col-6">
            <Form.Item
              name="Year"
              rules={[
                {
                  required: true,
                },
              ]}
              className="w-100"
            >
              <Select
                disabled={editRecord?.Year ? true : false} // 如果editRecord的Year有值則鎖定
              >
                {Array.from(
                  { length: 8 },
                  (_, i) => new Date().getFullYear() + 1 - i
                ).map((year) => (
                  <Select.Option value={year} key={year}>
                    <span className="d-flex justify-content-between">
                      {year}
                    </span>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>
      ),
    },
    {
      label: "綜合係數",
      key: "CustomElectricValue",
      Node: (
        <div className="row">
          <div className="col-3 text-end">
            <label htmlFor="CustomElectricValue">
              <span className="text-danger">*</span>{" "}
              {t("CompanyPara.CompositeFactor")} :
            </label>
          </div>
          <div className="col-6">
            <Form.Item
              name="CustomElectricValue"
              rules={[
                {
                  required: true,
                  message: `${t("CompanyPara.Plz")}${t(
                    "CompanyPara.CompositeFactor"
                  )}`,
                },
              ]}
            >
              <InputNumber
                className="w-100 text-end"
                min={0}
                placeholder={t("CompanyPara.CompositeFactor")}
                onChange={handleCustomElectricValueChange} // 當值改變時觸發事件
              />
            </Form.Item>
          </div>
        </div>
      ),
    },
    {
      label: "綜合係數",
      key: "CoefficientTable",
      Node: (
        <div className="row">
          <div className="col-2 text-end">
            <label htmlFor="CoefficientTable">
              {t("CompanyPara.Coefficient")} :
            </label>
          </div>
          <div className="col-9">
            <Form.Item name="CoefficientTable">
              <Table
                columns={coefficientColumns}
                dataSource={coefficientData}
                size="small"
                pagination={false}
                bordered
                rowKey="key"
                className="text-center"
              />
            </Form.Item>
          </div>
        </div>
      ),
    },
    {
      label: "說明",
      key: "Description",
      Node: (
        <div className="row">
          <div className="col-2 text-end">
            <label htmlFor="Description">
              {t("CompanyPara.Description")} :
            </label>
          </div>
          <div className="col-9">
            <Form.Item name="Description">
              <TextArea />
            </Form.Item>
          </div>
        </div>
      ),
    },
    {
      label: "資料來源名稱",
      key: "DisplayNameTW",
      Node: (
        <div className="row">
          <div className="col-3 text-end">
            <label htmlFor="Description">
              {t("CalculateForm.referenceFileName")} :
            </label>
          </div>
          <div className="col-8">
            <ProFormText
              key={uuidv4()}
              colProps={{ md: 8 }}
              name="DisplayNameTW"
            />
          </div>
        </div>
      ),
    },
    {
      label: "佐證資料上傳",
      key: "referenceFile",
      Node: (
        <div className="row">
          <div className="col-3 text-end">
            <label htmlFor="referenceFile">
              {t("CalculateForm.referenceFile")} :
            </label>
          </div>
          <div className="col-8 text-end">
            <ProFormUploadButton
              key={uuidv4()}
              colProps={{ md: 6 }}
              name="referenceFile"
              title={t("Buttons.Upload")}
              fieldProps={{
                multiple: true,
                fileList: fileList,
                onChange: handleFileChange,
                onRemove: handleFileRemove,
                beforeUpload: () => false, // 阻止文件上傳到伺服器
              }}
            />
          </div>
        </div>
      ),
    },
  ];
};

const REFRIGERANT_COMPANY_ADD_FORM = ({
  setEditData,
  form,
  editRecord,
  setResetChildState,
  ...props
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");

  // 定義重置函數
  const resetCoefficients = () => {
    setErrorMessage(""); // 重置錯誤訊息
  };

  // 定義上傳文件函數
  const [fileList, setFileList] = useState([]);
  useEffect(() => {
    if (editRecord?.SourceRefereneceFileLinks) {
      const initialFiles = editRecord.SourceRefereneceFileLinks.filter(
        (item) => item.RefereneceFileLink
      ).map((item) => ({
        uid: item.ID, // 使用 ID 作為唯一識別符
        name: item.RefereneceFileLink.split("/").pop(), // 提取文件名
        status: "done", // 設定狀態為已完成
        url: BASEURL + item.RefereneceFileLink, // 文件的 URL
      }));
      setFileList(initialFiles); // 初始化文件列表
    } else {
      setFileList([]);
    }
  }, [editRecord]);
  const handleFileChange = ({ file, fileList: newFileList }) => {
    // 處理新增文件邏輯
    if (file.status !== "removed") {
      setFileList(newFileList); // 更新文件列表
      form?.setFieldValue("SourceRefereneceFileLinks", newFileList);
    }
  };
  const handleFileRemove = (file) => {
    Modal.confirm({
      title: t("MessageText.Delete Item"), // 確認對話框的標題
      content: t("MessageText.Delete Confirmed"), // 確認對話框的內容
      okText: t("Buttons.Confirm"), // 確認按鈕的文本
      cancelText: t("Buttons.Cancel"), // 取消按鈕的文本
      onOk: () => {
        console.log("Removing file with uid:", file.uid);

        // 發送 DELETE 請求到 API，使用 file.uid 作為 sourceRefereneceFileLinksID
        axios
          .delete(`${Delete_CompanySourceRefereneceFileLinks}/${file.uid}`)
          .then((response) => {
            console.log("File deleted successfully:", response.data);

            // 刪除成功後，過濾掉被移除的文件，根據文件的 uid 來比對
            const updatedFileList = fileList.filter(
              (item) => item.uid !== file.uid
            );

            // 更新文件列表狀態
            setFileList(updatedFileList);

            // 更新表單的 "SourceRefereneceFileLinks" 欄位值
            form?.setFieldValue("SourceRefereneceFileLinks", updatedFileList);
          })
          .catch((error) => {
            console.error("Error deleting file:", error);
          });
      },
    });
  };

  // 使用 useEffect 傳遞重置函數給父層
  useEffect(() => {
    if (setResetChildState) {
      setResetChildState(() => resetCoefficients);
    }
  }, [setResetChildState]);

  return [
    {
      label: "綜合係數",
      key: "CustomElectricValue",
      Node: (
        <div className="row text-end mb-3">
          <div className="col-4">
            <label htmlFor="CustomElectricValue">
              {t("CompanyPara.EquipmentCategoryName")} :
            </label>
          </div>
        </div>
      ),
    },
    {
      label: "繁體",
      key: "DisplayNameTW",
      Node: (
        <div className="row">
          <div className="col-3 text-end">
            <label htmlFor="DisplayNameTW">
              <span className="text-danger">*</span>{" "}
              {t("ParameterList.NameTW")} :
            </label>
          </div>
          <div className="col-6">
            <Form.Item
              name="DisplayNameTW"
              rules={[
                {
                  required: true,
                  message: `${t("CompanyPara.Plz")}${t("ParameterList.NameTW")}`,
                },
              ]}
            >
              <InputNumber
                className="w-100 text-end"
                placeholder={t("ParameterList.NameTW")}
              />
            </Form.Item>
          </div>
        </div>
      ),
    },
    {
      label: "簡體",
      key: "DisplayNameCN",
      Node: (
        <div className="row">
          <div className="col-3 text-end">
            <label htmlFor="DisplayNameCN">
              {t("ParameterList.NameCN")} :
            </label>
          </div>
          <div className="col-6">
            <Form.Item
              name="DisplayNameCN"
            >
              <InputNumber
                className="w-100 text-end"
                placeholder={t("ParameterList.NameCN")}
              />
            </Form.Item>
          </div>
        </div>
      ),
    },
    {
      label: "英文",
      key: "DisplayNameEN",
      Node: (
        <div className="row">
          <div className="col-3 text-end">
            <label htmlFor="DisplayNameEN">
              {t("ParameterList.NameEN")} :
            </label>
          </div>
          <div className="col-6">
            <Form.Item
              name="DisplayNameEN"
            >
              <InputNumber
                className="w-100 text-end"
                placeholder={t("ParameterList.NameEN")}
              />
            </Form.Item>
          </div>
        </div>
      ),
    },
    {
      label: "逸散律",
      key: "Dissipation",
      Node: (
        <div className="row">
          <div className="col-3 text-end">
            <label htmlFor="Dissipation">
              <span className="text-danger">*</span>{" "}
              {t("CompanyPara.Dissipation")} :
            </label>
          </div>
          <div className="col-6">
            <Form.Item
              name="Dissipation"
              rules={[
                {
                  required: true,
                  message: `${t("CompanyPara.Plz")}${t(
                    "CompanyPara.Dissipation"
                  )}`,
                },
              ]}
            >
              <InputNumber
                className="w-100 text-end"
                placeholder={t("CompanyPara.Dissipation")}
              />
            </Form.Item>
          </div>
        </div>
      ),
    },
    {
      label: "說明",
      key: "Description",
      Node: (
        <div className="row">
          <div className="col-2 text-end">
            <label htmlFor="Description">
              {t("CompanyPara.Description")} :
            </label>
          </div>
          <div className="col-9">
            <Form.Item name="Description">
              <TextArea />
            </Form.Item>
          </div>
        </div>
      ),
    },
    {
      label: "資料來源名稱",
      key: "DisplayNameTW",
      Node: (
        <div className="row">
          <div className="col-3 text-end">
            <label htmlFor="Description">
              {t("CalculateForm.referenceFileName")} :
            </label>
          </div>
          <div className="col-8">
            <ProFormText
              key={uuidv4()}
              colProps={{ md: 8 }}
              name="DisplayNameTW"
            />
          </div>
        </div>
      ),
    },
    {
      label: "佐證資料上傳",
      key: "referenceFile",
      Node: (
        <div className="row">
          <div className="col-3 text-end">
            <label htmlFor="referenceFile">
              {t("CalculateForm.referenceFile")} :
            </label>
          </div>
          <div className="col-8 text-end">
            <ProFormUploadButton
              key={uuidv4()}
              colProps={{ md: 6 }}
              name="referenceFile"
              title={t("Buttons.Upload")}
              fieldProps={{
                multiple: true,
                fileList: fileList,
                onChange: handleFileChange,
                onRemove: handleFileRemove,
                beforeUpload: () => false, // 阻止文件上傳到伺服器
              }}
            />
          </div>
        </div>
      ),
    },
  ];
};

export { FORC1_ADD_FORM, POWERUSAGES_ADD_FORM, REFRIGERANT_COMPANY_ADD_FORM };
