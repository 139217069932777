import { v4 as uuidv4 } from "uuid";

const splitList = (list) => {
  let areaOptionSource = [];
  let optionList = [];
  if (Array.isArray(list)) {
    list.forEach(({ Key: key, Value = "" }) => {
      Value &&
        areaOptionSource.push({
          key,
          Area: Value?.split(",")[0] || "",
          FuelType: Value?.split(",")[1] || "",
          Year: Value?.split(",")[2] || "",
          OriHeatValue: Value?.split(",")[3] || "",
        });
    });
    list.forEach(({ ID, Name, intValue, itemNm }) => {
      (intValue || ID) &&
        optionList.push({
          label: Name || itemNm || "",
          value: ID || intValue || "",
        });
    });
  } else {
    for (let key in list) {
      optionList.push({
        value: parseInt(key),
        label: list[key],
        key: uuidv4(),
      });
    }
  }
  return { areaOptionSource, optionList };
};
const formatOption = (setAreaOptionList, { param = "", list, setFn }) => {
  let areas = [];
  let sourceTypes = [];
  const { areaOptionSource, optionList } = splitList(list);

  if (areaOptionSource.length > 0) {
    setAreaOptionList && setAreaOptionList(areaOptionSource);

    areas = new Set(areaOptionSource.map(({ Area }) => Area));
    sourceTypes = new Set(areaOptionSource.map(({ FuelType }) => FuelType));
    areas.forEach((a) => {
      let data = { value: a, label: a, children: [], key: uuidv4() };
      sourceTypes.forEach((type) => {
        if (
          areaOptionSource.some(
            ({ Area, FuelType }) => a === Area && FuelType === type
          )
        ) {
          const years = areaOptionSource
            .filter(({ Area, FuelType }) => a === Area && FuelType === type)
            .map(({ Year, key }) => ({
              value: key,
              label: Year,
              key,
            }))
            .sort((a, b) => b.label - a.label);

          data.children.push({ label: type, value: type, children: years });
        }
      });
      optionList.push(data);
      data = {};
    });
  }
  setFn(optionList);
};

// 給有區域性的 只需要 區域跟年
const generateParameterOptions = (
  setAreaOptionList,
  { param = "", list, setFn }
) => {
  let areas = [];
  let sourceTypes = [];
  const { areaOptionSource, optionList } = splitList(list);

  if (areaOptionSource.length > 0) {
    setAreaOptionList && setAreaOptionList(areaOptionSource);
    areas = new Set(areaOptionSource.map(({ Area }) => Area));
    sourceTypes = new Set(areaOptionSource.map(({ FuelType }) => FuelType));
    areas.forEach((a) => {
      let data = { value: a, label: a, children: [], key: uuidv4() };
      sourceTypes.forEach((type) => {
        if (
          areaOptionSource.some(
            ({ Area, FuelType }) => a === Area && FuelType === type
          )
        ) {
          const years = areaOptionSource
            .filter(({ Area, FuelType }) => a === Area && FuelType === type)
            .map(({ Year, key }) => ({
              value: key,
              label: Year,
              key,
            }))
            .sort((a, b) => b.label - a.label);
          data.children = years;
        }
      });
      optionList.push(data);
      data = {};
    });
  }
  setFn(optionList);
};

// 電力及蒸汽 format
const formatMonthOption = (setAreaOptionList, { param = "", list, setFn }) => {
  let areas = [];
  const { areaOptionSource, optionList } = splitList(list);

  if (areaOptionSource.length > 0) {
    setAreaOptionList && setAreaOptionList(areaOptionSource);
    areas = new Set(areaOptionSource.map(({ Area }) => Area));

    areas.forEach((a) => {
      let data = { value: a, label: a, children: [], key: uuidv4() };
      if (areaOptionSource.some(({ Area }) => a === Area)) {
        const years = areaOptionSource
          .filter(({ Area }) => a === Area)
          .map(({ Year, key }) => ({
            value: key,
            label: Year,
            key,
          }))
          .sort((a, b) => b.label - a.label);
        data.children = years;
      }

      optionList.push(data);
      data = {};
    });
  }
  setFn(optionList);
};

export { formatOption, generateParameterOptions, formatMonthOption };
