import { useTranslation } from "react-i18next";

function PwdRules() {
  let pwdRule = localStorage.getItem("PLENGTH");
  const { t } = useTranslation();
  const RULES = [
    {
      pattern: /[A-Z]{1,}/,
      message: t("User.MustContainUppercaseLetter"),
    },
    // 須包含一個小寫字母
    {
      pattern: /[a-z]{1,}/,
      message: t("User.MustContainLowercaseLetter"),
    },
    // 須包含一個特殊符號
    {
      pattern: /[!@#$%^&*]{1,}/,
      message: t("User.MustContainSpecialSymbol"),
    },
    // 須包含一個數字
    {
      pattern: /\d{1,}/,
      message: t("User.MustContainNumber"),
    },
    // 長度
    {
      type: "string",
      min: Number(pwdRule),
      max: 50, // 最多不超过50个字符
      message: `${t("User.LengthLongerThan")}${pwdRule}${t("User.LengthLessThan")}`,
    },
  ];

  return RULES;
};

export default PwdRules;
