import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

const TabLabel = ({ label }) => {
  const { t } = useTranslation();
  return t(`Calculate.${label}`);
};

const addClass = (list) =>
  list.map(
    ({
      label,
      key,
      link,
      editType = "",
      type = "",
      notes = [],
      info = {
        apiParaForGet: "",
        apiParaForPost: "",
        LifeCycleStage: "",
        GHGinventoryItemNo: "",
        apiGHGItemNoIsDisabled: false,
      },
    }) =>
      type || type === 0
        ? {
            addFormTitle: label,
            label: <TabLabel label={label} />,
            key,
            link,
            className: "mx-2",
            editType,
            type,
            info,
            notes,
          }
        : {
            addFormTitle: label,
            label: <TabLabel label={label} />,
            key,
            link,
            className: "mx-2",
            editType,
            type: info.GHGinventoryItemNo,
            info,
            notes,
          }
  );

/*
  link 要找對應的 api 用 (判斷 param 用)
*/

// 上游
const CAPITALGOOD_OPTION = addClass([
  {
    label: "capitalGood",
    key: "capitalGood",
    link: "LifeCycle",
    editType: "lifecycle", // 必須對應到 chooseCalculateTable 中的 case 判斷值
    // type: "資本財 (產生之排放)",
    info: {
      category: "4",
      LifeCycleStage: "3",
      GHGinventoryItemNo: "4.2",
    },
  },
]);
const UPSTREAMENERGYEMISSION_OPTION = addClass([
  {
    label: "upstreamElectricity",
    key: "upstreamElectricity",
    link: "LifeCycle",
    editType: "lifecycle",
    // type: "輸入電力上游", // GHGEvaluateItem
    info: {
      category: "4",
      LifeCycleStage: "1",
      GHGinventoryItemNo: "4.1b",
      SimaProOption: 26,
    },
  },
  {
    label: "electricityEnergy",
    key: "electricityEnergy",
    link: "LifeCycle",
    editType: "lifecycle",
    // type: "輸入能源上游", // GHGEvaluateItem
    info: {
      category: "4",
      LifeCycleStage: "1",
      GHGinventoryItemNo: "4.1b2",
      SimaProOption: 26,
    },
  },
]);

const WATER_OPTION = addClass([
  {
    label: "waterUsage",
    key: "waterUsage",
    link: "LifeCycle",
    editType: "lifecycle",
    // type: "用水(水資源管理)", // GHGEvaluateItem
    info: {
      excelDownload: "/excel_sample/water_minus_0317.xlsx",
      category: "4",
      LifeCycleStage: "3",
      GHGinventoryItemNo: "4.7",
      SimaProOption: 27,
    },
  },
]);

const DISPOSAL_OPTION = addClass([
  {
    label: "disposal",
    key: "disposal",
    link: "LifeCycle",
    editType: "lifecycle",
    info: {
      category: "4",
      LifeCycleStage: "3",
      GHGinventoryItemNo: "4.3",
      SimaProOption: 1,
    },
  },
]);
const LEASEDASSET_OPTION = addClass([
  {
    label: "upstreamLeasedAsset",
    key: "leasedAsset",
    link: "LifeCycle",
    editType: "lifecycle",
    // type: "上游租賃設備資產",
    info: {
      category: "4",
      LifeCycleStage: "3",
      GHGinventoryItemNo: "4.4",
    },
  },
]);
const PURCHASEDGOOD_OPTION = addClass([
  {
    label: "purchasedGood",
    key: "PurchasedGood",
    link: "PurchasedGood",
    editType: "lifecycle",
    // type: "組織採購的貨物",
    info: {
      category: "4",
      LifeCycleStage: "1",
      GHGinventoryItemNo: "4.1",
      apiGHGItemNoIsDisabled: true,
    },
  },
]);

const CONSULTANT_OPTION = addClass([
  {
    label: "consultant",
    key: "consultant",
    link: "LifeCycle",
    editType: "lifecycle",
    info: {
      category: "4",
      LifeCycleStage: "3",
      GHGinventoryItemNo: "4.5",
    },
  },
]);

const UPSTREAMTRANSPORT_OPTION = addClass([
  {
    label: "upstreamTransport",
    key: "UpstreamTransport",
    link: "UpstreamTransport",
    editType: "lifecycle",
    // type: "貨物上游運輸與配送",
    info: {
      LifeCycleStage: "2",
      category: "3",
      GHGinventoryItemNo: "3.1",
      SimaProOption: 14,
      // apiGHGItemNoIsDisabled: true,
    },
  },
]);

const VISITOR_OPTION = addClass([
  {
    label: "visitor",
    key: "visitor",
    link: "Visitor",
    editType: "lifecycle",
    // type: "運輸客戶和訪客",
    info: {
      LifeCycleStage: "3",
      category: "3",
      GHGinventoryItemNo: "3.4",
      SimaProOption: 14,
    },
  },
]);

const BUSINESSTRAVEL_OPTION = addClass([
  {
    label: "businessTravel",
    key: "businessTravel",
    link: "BusinessTravel",
    editType: "lifecycle",
    // type: "業務旅運 (商務旅行)",
    info: {
      LifeCycleStage: "3",
      category: "3",
      GHGinventoryItemNo: "3.5",
      SimaProOption: 14,
    },
  },
]);
const COMMUTING_OPTION = addClass([
  {
    label: "commuting",
    key: "Commuting",
    link: "Commuting",
    editType: "lifecycle",
    info: {
      LifeCycleStage: "3",
      category: "3",
      GHGinventoryItemNo: "3.3",
      SimaProOption: 14,
      // apiGHGItemNoIsDisabled: true,
    },
  },
]);

// 事業 sourceIconListForBusinessS1
// 固定
const STATIONARYCOMBUSTION_OPTION = addClass([
  {
    label: "stationaryCombustion",
    key: "stationaryCombustion",
    link: "Kitchens",
    type: 1,
    editType: "device",
    notes: [
      {
        key: uuidv4(),
        text: "stationaryCombustion1",
      },
      {
        key: uuidv4(),
        text: "stationaryCombustion2",
      },
    ],
    info: {
      excelDownload: "/excel_sample/StationaryCombustionSource.xlsx",
      apiParaForGet: 1,
      apiParaForPost: 0,
      addParaCategory: "8",
      warmGasType: 1,
    },
  },
]);
// 移動
const MOBILECOMBUSTION_OPTION = addClass([
  {
    label: "mobileCombustion",
    key: "Vehicles",
    link: "Vehicles",
    editType: "device",
    notes: [
      {
        key: uuidv4(),
        text: "mobileCombustion1",
      },
      {
        key: uuidv4(),
        text: "mobileCombustion2",
      },
      {
        key: uuidv4(),
        text: "mobileCombustion3",
      },
      {
        key: uuidv4(),
        text: "mobileCombustion4",
      },
    ],
    info: {
      excelDownload: "/excel_sample/Vehicle.xlsx",
      warmGasType: 2,
      addParaCategory: "6",
    },
  },
  // {
  //   addFormTitle: "移動源 B.2.2.b",
  //   label: "汽油 B.2.2.b",
  //   key: "gasoline",
  //   type: "汽油",
  //   link: "vehicles",
  //   notes: [
  //     {
  //       key: uuidv4(),
  //       text: `若非為公司名下登記之車輛皆為非公務車之定義，如租賃,私車等車輛使用於公務上的部分則納入"其他燃料"。`,
  //     },
  //     {
  //       key: uuidv4(),
  //       text: ` 將盤查期間，廠區內所有公務車列出，將車型、車牌號碼標上，並附上佐證資料。`,
  //     },
  //     {
  //       key: uuidv4(),
  //       text: "佐證資料之來源可由原始的加油單據(加油量)、車隊的加油卡或加油記錄，或是由財務報帳資料扣除維修或是過路收費等非加油費用後，除以平均油價換算成加油量。",
  //     },
  //     {
  //       key: uuidv4(),
  //       text: "平均油價可至中油網站或是中石油、中石化的網站查詢，將盤查期間的油價波動平均，即為平均油價。",
  //     },
  //   ],
  //   info: {
  //     excelDownload: "/excel_sample/Vehicle_0505.xlsx",
  //   },
  // },
]);
// 逸散
const DIRECTFUGITIVEEMISSION_OPTION = addClass([
  {
    label: "workHour",
    key: "WorkHour",
    link: "WorkHour",
    editType: "month",
    notes: [
      {
        key: uuidv4(),
        text: "workHour1",
      },
      {
        key: uuidv4(),
        text: "workHour2",
      },
      {
        key: uuidv4(),
        text: "workHour3",
      },
    ],
    info: {
      excelDownload: "/excel_sample/workhour.xlsx",
      addParaCategory: "0",
      category: 0,
    },
  },
  {
    label: "refrigerant",
    key: "Refrigerant",
    link: "Refrigerant",
    editType: "nonYearDevice",
    notes: [
      {
        key: uuidv4(),
        text: "refrigerant1",
      },
      {
        key: uuidv4(),
        text: "refrigerant2",
      },
    ],
    info: {
      excelDownload: "/excel_sample/RFG.xlsx",
      addParaCategory: "9",
    },
  },
  {
    label: "fireEquipment",
    key: "FireEquipment",
    link: "FireEquipment",
    editType: "nonYearDevice",
    notes: [
      {
        key: uuidv4(),
        text: "fireEquipment1",
      },
      {
        key: uuidv4(),
        text: "fireEquipment2",
      },
    ],
    info: {
      excelDownload: "/excel_sample/Fire.xlsx",
      addParaCategory: "11",
    },
  },
]);
// 製程
const DIRECTPROCESSEMISSION_OPTION = addClass([
  {
    label: "directProcessEmission",
    key: "directProcessEmission",
    link: "Process",
    editType: "device",
    type: 4,
    notes: [
      {
        key: uuidv4(),
        text: "directProcessEmission1",
      },
      {
        key: uuidv4(),
        text: "directProcessEmission2",
      },
      {
        key: uuidv4(),
        text: "directProcessEmission3",
      },
      {
        key: uuidv4(),
        text: "directProcessEmission4",
      },
    ],
    info: {
      excelDownload: "/excel_sample/IndustrialProcess.xlsx",
      apiParaForGet: 4, // ! warmGasType
      apiParaForPost: 0,
      addParaCategory: "8",
      warmGasType: 4,
    },
  },
]);

// 其他關注
const OTHERCOMPOUNDS_OPTION = addClass([
  {
    label: "otherCompound",
    key: "OtherCompounds",
    link: "OtherCompounds",
    editType: "nonYearDevice",
    notes: [
      {
        key: uuidv4(),
        text: "otherCompound",
      },
    ],
    info: {
      excelDownload: "/excel_sample/other.xlsx",
      addParaCategory: "13",
    },
  },
]);

// 事業 sourceIconListForBusinessS2
// 用電量
const ELECTRICITY_OPTION = addClass([
  {
    label: "powerUsages",
    key: "powerUsages",
    link: "PowerUsages",
    type: 0,
    editType: "month",
    notes: [
      {
        key: uuidv4(),
        text: "powerUsages1",
      },
      {
        key: uuidv4(),
        text: "powerUsages2",
      },
      {
        key: uuidv4(),
        text: "powerUsages3",
      },
      {
        key: uuidv4(),
        text: "powerUsages4",
      },
      {
        key: uuidv4(),
        text: "powerUsages5",
      },
    ],
    info: {
      addParaCategory: "1",
      excelDownload: "/excel_sample/Elec.xlsx",
    },
  },
  {
    label: "greenElectricity",
    key: "greenElectricity",
    link: "GreenPowerUsages",
    type: 1,
    editType: "month",
    notes: [
      {
        key: uuidv4(),
        text: "powerUsages1",
      },
      {
        key: uuidv4(),
        text: "powerUsages2",
      },
      {
        key: uuidv4(),
        text: "powerUsages3",
      },
      {
        key: uuidv4(),
        text: "powerUsages4",
      },
      {
        key: uuidv4(),
        text: "powerUsages5",
      },
    ],
    info: {
      excelDownload: "/excel_sample/GreenElec.xlsx",
    },
  },
]);
// 蒸氣
const STEAM_OPTION = addClass([
  {
    label: "steamPlus",
    key: "steamPlus",
    link: "SteamUsages",
    type: "+", // PlusMinus
    editType: "month",
    notes: [
      {
        key: uuidv4(),
        text: "steam1",
      },
      { key: uuidv4(), text: "steam2" },
    ],
    info: {
      excelDownload: "/excel_sample/steam_plus.xlsx",
      addParaCategory: "12",
    },
  },
  {
    label: "steamMinus",
    key: "steamMinus",
    link: "SteamUsages",
    type: "-", // PlusMinus
    editType: "month",
    notes: [
      {
        key: uuidv4(),
        text: "steam1",
      },
      { key: uuidv4(), text: "steam2" },
    ],
    info: {
      excelDownload: "/excel_sample/steam_minus.xlsx",
      addParaCategory: "12",
    },
  },
]);

// 下游 sourceIconListForDownstreamS3
// 廢棄物
const DISPOSALDOWNTRANSPORT_OPTION = addClass([
  {
    label: "disposalDownTransport",
    key: "DisposalDownTransport",
    link: "UpstreamTransport",
    editType: "lifecycle",
    info: {
      LifeCycleStage: "3",
      category: "3",
      GHGinventoryItemNo: "3.2.2",
      SimaProOption: 15,
    },
  },
]);
const USEEMISSION_OPTION = addClass([
  {
    label: "useEmission",
    key: "useEmission",
    link: "LifeCycle",
    editType: "lifecycle",
    // type: "產品使用階段",
    info: {
      category: "5",
      LifeCycleStage: "5",
      GHGinventoryItemNo: "5.1",
    },
  },
]);

const DOWNSTREAMDISPOSAL_OPTION = addClass([
  {
    label: "downstreamDisposal",
    key: "downstreamDisposal",
    link: "LifeCycle",
    editType: "lifecycle",
    // type: "產品生命終止階段",
    info: {
      category: "5",
      LifeCycleStage: "6",
      GHGinventoryItemNo: "5.3",
    },
  },
]);

const INVESTMENT_OPTION = addClass([
  {
    label: "investment",
    key: "investment",
    link: "LifeCycle",
    editType: "lifecycle",
    // type: "投資",
    info: {
      category: "5",
      LifeCycleStage: "3",
      GHGinventoryItemNo: "5.4",
    },
  },
]);
const DOWNLEASEDASSET_OPTION = addClass([
  {
    label: "downstreamLeasedAsset",
    key: "downLeasedAsset",
    link: "LifeCycle",
    editType: "lifecycle",
    // type: "下游承租資產",
    info: {
      category: "5",
      LifeCycleStage: "3",
      GHGinventoryItemNo: "5.2",
    },
  },
]);
const DOWNSTREAMTRANSPORT_OPTION = addClass([
  {
    label: "downstreamTransport",
    key: "downstreamTransport",
    link: "UpstreamTransport",
    editType: "lifecycle",
    // type: "貨物下游運輸與配送",
    info: {
      LifeCycleStage: "4",
      category: "3",
      GHGinventoryItemNo: "3.2.1",
      SimaProOption: 14,
    },
  },
]);

//sourceIconListForOtherS3
const OTHER_OPTION = addClass([
  {
    label: "other",
    key: "other",
    link: "LifeCycle",
    editType: "lifecycle",
    info: {
      category: "6",
      LifeCycleStage: "7",
      GHGinventoryItemNo: "6",
      addParaCategory: "13",
    },
  },
]);

const ALL_OPTION = [
  ...CAPITALGOOD_OPTION,
  ...UPSTREAMENERGYEMISSION_OPTION,
  ...WATER_OPTION,
  ...DISPOSAL_OPTION,
  ...PURCHASEDGOOD_OPTION,
  ...CONSULTANT_OPTION,
  ...LEASEDASSET_OPTION,
  ...UPSTREAMTRANSPORT_OPTION,
  ...BUSINESSTRAVEL_OPTION,
  ...VISITOR_OPTION,
  ...COMMUTING_OPTION,
  ...DISPOSALDOWNTRANSPORT_OPTION,
  ...STATIONARYCOMBUSTION_OPTION,
  ...MOBILECOMBUSTION_OPTION,
  ...DIRECTFUGITIVEEMISSION_OPTION,
  ...DIRECTPROCESSEMISSION_OPTION,
  ...OTHERCOMPOUNDS_OPTION,
  ...ELECTRICITY_OPTION,
  ...STEAM_OPTION,
  ...USEEMISSION_OPTION,
  ...DOWNSTREAMDISPOSAL_OPTION,
  ...INVESTMENT_OPTION,
  ...DOWNLEASEDASSET_OPTION,
  ...DOWNSTREAMTRANSPORT_OPTION,
  ...OTHER_OPTION,
];

export {
  CAPITALGOOD_OPTION,
  UPSTREAMENERGYEMISSION_OPTION,
  WATER_OPTION,
  DISPOSAL_OPTION,
  PURCHASEDGOOD_OPTION,
  CONSULTANT_OPTION,
  LEASEDASSET_OPTION,
  UPSTREAMTRANSPORT_OPTION,
  DOWNSTREAMTRANSPORT_OPTION,
  BUSINESSTRAVEL_OPTION,
  VISITOR_OPTION,
  COMMUTING_OPTION,
  DISPOSALDOWNTRANSPORT_OPTION,
  STATIONARYCOMBUSTION_OPTION,
  MOBILECOMBUSTION_OPTION,
  DIRECTFUGITIVEEMISSION_OPTION,
  DIRECTPROCESSEMISSION_OPTION,
  OTHERCOMPOUNDS_OPTION,
  ELECTRICITY_OPTION,
  STEAM_OPTION,
  USEEMISSION_OPTION,
  DOWNSTREAMDISPOSAL_OPTION,
  INVESTMENT_OPTION,
  DOWNLEASEDASSET_OPTION,
  OTHER_OPTION,
  ALL_OPTION,
};
