import { Typography, Layout } from "antd";
import { useTranslation } from "react-i18next";
const { Footer } = Layout;
function VersionFooter({ className = "" }) {
  const { t } = useTranslation();
  return (
    <Footer className={`d-flex flex-column text-center bg-transparent py-2 ${className}`}>
      <h6 className="mt-2 mb-0">{t("User.Info")}</h6>
      <h6 className="mb-2">{t("User.Info_B")}</h6>
      {/* <Typography.Text style={{ fontSize: "14px", textAlign: "center" }}>
        2023 © NetZeroPro.com.tw
      </Typography.Text> */}
      <Typography.Text style={{ fontSize: "14px", textAlign: "center" }}>
        {t("Version")}：v1.1.21204 (2.40807)
      </Typography.Text>
    </Footer>
  );
}

export default VersionFooter;
