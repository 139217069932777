import { t } from "i18next";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal, Button } from "antd";
const { confirm } = Modal;

const showDeleteConfirm = (deleteFn, { apiPath = "", ID = "" }) => {
  confirm({
    title: t("MessageText.Delete Item"),
    icon: <ExclamationCircleFilled />,
    content: t("MessageText.Delete Confirmed"),
    okText: t("Buttons.Confirm"),
    okType: "danger",
    cancelText: t("Buttons.Cancel"),
    onOk() {
      if (apiPath) {
        deleteFn({ apiPath, ID });
      } else {
        deleteFn();
      }
    },
    onCancel() {
      console.log("Cancel");
    },
  });
};

export default showDeleteConfirm;
