import React, { useEffect, useState, createContext, useRef } from "react";
import { Button } from "antd";
import { useSelector } from "react-redux";
import CalculateGoogleMapModal from "./CalculateGoogleMapModal";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import {
  ProFormUploadButton,
  ProFormSelect,
  ProFormText,
} from "@ant-design/pro-components";
import { BASEURL, GETTRANSPORTHISTORYOPTION } from "@CON";
import axios from "axios";
import { NameTranslate } from "@CON/calculateAddForm";

export const FormContext = createContext(null);

function CalculateGoogleMap({
  form,
  ori,
  des,
  distance,
  googleMapApiUse,
  children,
}) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalKey, setModalKey] = useState(uuidv4());
  const [totalDistance, setTotalDistance] = useState(0);
  const [referencePhotoUrl, setReferencePhotoUrl] = useState(
    form?.getFieldValue("ReferenceServicePhotoUrl") || null
  );

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setModalKey(uuidv4());
  };

  const googleMapImg = useRef(null);
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    const url = form?.getFieldValue("ReferenceServicePhotoUrl");
    setImageSrc(url);

    const record = form?.getFieldValue("TransportRecordNumber")
    if(record){
      setGoogleMapBtnIsDisabled(true)
    }
  }, [form]);

  // console.log("googleMapApiUse", googleMapApiUse);

  const {
    project: {
      LCAInfo: { ID },
    },
  } = useSelector((state) => state);

  const [googlMapBtnIsDisabled, setGoogleMapBtnIsDisabled] = useState(false);
  const onChange = (_, option) => {
    let origin = "",
      destination = "",
      photoUrl = null,
      files = [],
      distanceVal = 0;
    if (!option.value) {
      setGoogleMapBtnIsDisabled(false);
    } else {
      // 修改form ori & des 位置
      // 有值就不可修改ori and des
      setGoogleMapBtnIsDisabled(true);
      distanceVal = option.item.TransportDistance;
      origin = option.item.StartLocation;
      destination = option.item.EndLocation;
      photoUrl = option.item.ReferenceServicePhotoUrl;
      files = option.item.ReferenceServicePhotoUrl
        ? [
            {
              uid: uuidv4(),
              name: option.item.ReferenceServicePhotoUrl?.split("/")[
                option.item.ReferenceServicePhotoUrl?.split("/").length - 1
              ],
              status: "done",
              url: option.item.ReferenceServicePhotoUrl.startsWith(BASEURL)
                ? option.item.ReferenceServicePhotoUrl
                : BASEURL + option.item.ReferenceServicePhotoUrl,
            },
          ]
        : [];
    }

    form.setFieldValue(ori, origin);
    form.setFieldValue(des, destination);
    form?.setFieldValue("ReferenceServicePhotoUrl", photoUrl);
    setImageSrc(`${BASEURL}/${photoUrl}`);
    form?.setFieldValue("referencePhoto", files);
    form.setFieldValue(distance, distanceVal);
  };

  const [oriDesHistoryOptions, setOriDesHistoryOptions] = useState([]);
  const getOriDesHistory = async () => {
    const res = await axios.get(`${GETTRANSPORTHISTORYOPTION}/${ID}`);

    setOriDesHistoryOptions([
      { label: "自行輸入", value: null, item: null },
      ...res.map((d) => ({
        label: `${d.StartLocation} -> ${d.EndLocation}`,
        value: d.ID,
        item: d,
      })),
    ]);
  };

  useEffect(() => {
    getOriDesHistory();
  }, []);

  return (
    <>
      <ProFormSelect
        allowClear={false}
        key={uuidv4()}
        colProps={{ md: 12 }}
        options={oriDesHistoryOptions}
        name="TransportRecordNumber"
        label="起訖點歷史資料"
        onChange={onChange}
      />
      <ProFormText
        key={uuidv4()}
        label={<NameTranslate name="UpstreamTransport.StartLocation" />}
        name={ori}
        colProps={{ md: 12 }}
        disabled={googlMapBtnIsDisabled}
      />
      <ProFormText
        key={uuidv4()}
        label={<NameTranslate name="UpstreamTransport.EndLocation" />}
        name={des}
        colProps={{ md: 12 }}
        disabled={googlMapBtnIsDisabled}
      />
      <div className="d-flex flex-column">
        <Button
          type="primary"
          onClick={showModal}
          className="mb-3"
          disabled={googleMapApiUse || googlMapBtnIsDisabled}
        >
          {t("Buttons.GoogleDistance")}
        </Button>
        {imageSrc && (
          <img
            id="google-map"
            ref={googleMapImg}
            src={imageSrc}
            width="100%"
            className="my-2"
            alt=""
          />
        )}
      </div>
      <FormContext.Provider
        value={{
          form,
          ori,
          des,
          distance,
          handleCancel,
          isModalOpen,
          googleMapImg,
          setImageSrc,
          onRemove: () => {
            // 清除顯示的圖片
            form?.setFieldValue("ReferenceServicePhotoUrl", null);
            form?.setFieldValue("referencePhoto", []);
            setReferencePhotoUrl(null); // 更新狀態
            setImageSrc(""); // 清空圖片的 src
          },
        }}
      >
        <CalculateGoogleMapModal
          key={modalKey}
          totalDistance={totalDistance}
          setTotalDistance={setTotalDistance}
        />
      </FormContext.Provider>

      {/* ProFormUploadButton */}
      <ProFormUploadButton
        key={uuidv4()}
        colProps={{ md: 6 }}
        label={t("UpstreamTransport.mapPhoto")}
        name="referencePhoto"
        title={t("Buttons.Upload")}
        fieldProps={{
          onChange: ({ file }) => {
            if (file.status === "removed") {
              form?.setFieldValue("ReferenceServicePhotoUrl", null); // 清空圖片路徑
              form?.setFieldValue("referencePhoto", []); // 清空上傳檔案
            } else {
              form?.setFieldValue("referencePhoto", [file]); // 設定新上傳的檔案
            }
          },
          onRemove: (file) => {
            // 清空圖片顯示
            form?.setFieldValue("ReferenceServicePhotoUrl", null); // 清空圖片路徑
            form?.setFieldValue("referencePhoto", []); // 清空上傳檔案
            setReferencePhotoUrl(null); // 更新狀態
            setImageSrc("");
          },
          beforeUpload: () => {
            return false; // 禁止直接上傳
          },
        }}
      />
    </>
  );
}

export default CalculateGoogleMap;
