import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import axios from "axios";
import { Spin } from "antd";
import { StyleProvider } from "@ant-design/cssinjs";
import routes from "./router/routes";
import { persistor, store } from "@STORE";
import { messageGenerator } from "@UTIL";
import "./index.scss";
import "antd/dist/reset.css";
import "./i18n";

// 添加响应拦截器
axios.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    const { config, data } = response;
    return data;
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    if (400 === error.response.status) {
      error.response.data.errMsg &&
        messageGenerator({ content: error.response.data.errMsg });
    } else if (401 === error.response.status) {
      window.sessionStorage.setItem("redirectUrl", window.location.pathname);
      window.location = "/login";
    } else if (error.response.status === 500) {
      messageGenerator({
        content: "伺服器發生點錯誤，請重新整理後或登出再嘗試！",
      });
    } else {
      messageGenerator({
        content: "發生錯誤，請重新整理後或登出再嘗試！",
      });
    }
    return error.response;
  }
);

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const router = createBrowserRouter(routes, {
  basename: baseUrl,
});

root.render(
  <StyleProvider hashPriority="high">
    <Provider store={store}>
      <Suspense
        fallback={
          <div className="vw-100 vh-100 d-flex align-items-center justify-content-center">
            <Spin size="large" />
          </div>
        }
      >
        <PersistGate persistor={persistor}>
          <RouterProvider router={router} />
        </PersistGate>
      </Suspense>
    </Provider>
  </StyleProvider>
);
