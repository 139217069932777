const colorList = [
  "#fb9968", "#b2cf87", "#c08eaf", "#F596AA", "#9e737d",
  "#bec936", "#9b1e64", "#add5a2", "#5a1216", "#5e7987",
  "#806d9e", "#15559a", "#12a182", "#126e82", "#e9ccd3",
  "#ffcc99", "#6699cc", "#ff9966", "#996699", "#669966",
  "#cc3366", "#666699", "#996633", "#cc9966", "#cc6699",
  "#339999", "#99cc33", "#66cc66", "#cc6699", "#66cccc",
  "#ff9999", "#66ccff", "#99cc00", "#ffcc00", "#ff6600",
  "#cc66ff", "#ff00cc", "#cc0000", "#0099cc", "#00cc66",
  "#ccccff", "#ccff66", "#ff6699", "#66ffcc", "#669999",
  "#996666", "#ff9933", "#cc9966", "#66cc33", "#ffccff",
];

const colorList2 = [
  "#E1BEE7", "#e2d849", "#eea08c", "#F8C3CD", "#f8df72",
  "#DCEDC8", "#B2EBF2", "#C5CAE9", "#A8D8B9", "#fb9968",
  "#b2cf87", "#c08eaf", "#F596AA", "#f19790", "#A8D8B9",
  "#d1c2d3", "#5cb3cc", "#b0d5df", "#ea8958", "#b7a091",
  "#f9e9cd", "#ffcc99", "#6699cc", "#ff9966", "#996699",
  "#669966", "#cc3366", "#666699", "#996633", "#cc9966",
  "#E6E6FA", "#FFF0F5", "#F0FFF0", "#F5FFFA", "#F0FFFF",
  "#F5F5DC", "#FFFACD", "#FFE4E1", "#FAF0E6", "#D3D3D3",
  "#C0C0C0", "#D2B48C", "#8FBC8F", "#E9967A", "#D8BFD8",
  "#FF6347", "#40E0D0", "#EE82EE", "#F5DEB3", "#FFFF00",
];


export { colorList, colorList2 };
