const INDUSTRYTYPELIST = [
  "Other",
  "Apparel, Accessories and Footwear",
  "Appliance Manufacturing",
  "Building Products and Furnishings",
  "Household and Personal Products",
  "Toys and Sporting Goods",
  "Multiline and Specialty Retailers and Distributors",
  "E-commerce",
  "Coal Operations",
  "Construction Materials",
  "Iron and Steel Producers",
  "Metals and Mining",
  "Oil and Gas - Exploration and Production",
  "Oil and Gas - Midstream",
  "Oil and Gas - Refining and Marketing",
  "Oil and Gas - Services",
  "Asset Management and Custody Activities",
  "Investment Banking and Brokerage",
  "Security and Commodity Exchanges",
  "Commercial Banks",
  "Consumer Finance",
  "Mortgage Finance",
  "Insurance",
  "Agricultural Products",
  "Meat, Poultry and Dairy",
  "Processed Foods",
  "Alcoholic Beverages",
  "Non-Alcoholic Beverages",
  "Food Retailers and Distributors",
  "Restaurants",
  "Tobacco",
  "Biotechnology and Pharmaceuticals",
  "Drug Retailers",
  "Health Care Delivery",
  "Health Care Distributors",
  "Managed Care",
  "Medical Equipment and Supplies",
  "Electric Utilities and Power Generators",
  "Gas Utilities and Distributors",
  "Water Utilities and Services",
  "Engineering and Construction Services",
  "Home Builders",
  "Real Estate",
  "Real Estate Services",
  "Waste Management",
  "Biofuels",
  "Fuel Cells and Industrial Batteries",
  "Solar Technology and Project Developers",
  "Wind Technology and Project Developers",
  "Forestry Management",
  "Pulp and Paper Products",
  "Aerospace and Defense",
  "Containers and Packaging",
  "Electrical and Electronic Equipment",
  "Industrial Machinery and Goods",
  "Chemicals",
  "Advertising and Marketing",
  "Media and Entertainment",
  "Casinos and Gaming",
  "Hotels and Lodging",
  "Leisure Facilities",
  "Education",
  "Professional and Commercial Services",
  "Electronic Manufacturing Services and Original Design Manufacturing",
  "Hardware",
  "Software and IT Services",
  "Internet Media and Services",
  "Semiconductors",
  "Telecommunication Services",
  "Airlines",
  "Air Freight and Logistics",
  "Automobiles",
  "Auto Parts",
  "Car Rental and Leasing",
  "Cruise Lines",
  "Marine Transportation",
  "Rail Transportation",
  "Road Transportation",
];

export default INDUSTRYTYPELIST.map((industry, index) => ({
  label: industry,
  value: index,
}));
