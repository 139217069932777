import { createSlice } from "@reduxjs/toolkit";
import { formatDateForChart } from "@UTIL";

const companySlice = createSlice({
  name: "analyse",
  initialState: {
    compareType: "", // 決定比較的是 S1~S3 / S1~S2
    compareYear: [], // 初始比較的年區間
    selectedDate: null, // 長條圖點選的月份、年份
    compareFabs: [],
    totalData: [], // 第一層
    totalValues: [],
    scope: "直接排放", // 第二層   直接排放就是 S1 間接 S2
    scopeData: [],
    scopeValues: [],
    sourceType: "燃燒", // 第三層
    sourceTypeData: [],
    sourceTypeValues: [],
    deviceData: [], // 第四層，只有燃燒類有
    deviceValues: [],
  },
  reducers: {
    setCompareFabs: (
      state,
      { payload: { compareFabs, compareType, yearList } }
    ) => {
      state.compareFabs = compareFabs;
      state.compareType = compareType;
      state.compareYear = yearList;
    },
    setTotalData: (state, { payload }) => {
      state.totalData = payload;
    },
    setScope: (state, { payload }) => {
      state.scope = payload;
    },
    setSourceType: (state, { payload }) => {
      state.sourceType = payload;
    },
    c: (state, { payload }) => {
      state.totalData = payload;
    },
    setDeviceData: (state, { payload }) => {
      state.totalData = payload;
    },
    // 用來格式化目前的年份組合==>主要用在長條圖點選上
    setSelectedDate: (state, { payload }) => {
      let sortedCompareYear = payload.sort((acc, cur) => acc - cur); // 將年份按序編排
      let StartDate = "";
      let EndDate = "";

      if (sortedCompareYear.length > 0) {
        StartDate = formatDateForChart(sortedCompareYear[0]); // 第一個是最小年分
        EndDate = formatDateForChart(
          `${sortedCompareYear[sortedCompareYear.length - 1]}-12-31`
        ); // 最大年份必須加 1
      }
      state.selectedDate = { StartDate, EndDate };
    },
  },
});

const { reducer, actions } = companySlice;
export const {
  setTotalData,
  setScope,
  setSourceType,
  setDeviceData,
  setSelectedDate,
  setCompareFabs,
  setCompareYear,
} = actions;

export default reducer;
