import { InfoCircleOutlined } from "@ant-design/icons";

export default function CalculateFormula({ children }) {
  return (
    <div className="d-flex justify-content-start mb-1">
      <InfoCircleOutlined />
      <small className="ms-2">{children}</small>
    </div>
  );
}
