import { useState, useMemo, useEffect } from "react";

function useFilterVerificationData() {
  const [clauseList, setClauseList] = useState([]);
  const [filterFormContent, setFilterFormContent] = useState({
    clauseFilter: [],
    resultFilter: "",
  });

  const filterData = useMemo(() => {
    const { clauseFilter, resultFilter } = filterFormContent;

    let displayItems =
      clauseFilter?.length > 0
        ? clauseList.filter(({ GroupID }) => clauseFilter.includes(GroupID))
        : clauseList;
    displayItems =
      resultFilter !== undefined
        ? displayItems.filter(({ Value }) => Value === resultFilter)
        : displayItems;
    return displayItems.sort(
      (a, b) => a.VerificationTemplateTitleID - b.VerificationTemplateTitleID
    );
  }, [clauseList, filterFormContent]);

  return { filterData, clauseList, setClauseList, setFilterFormContent };
}

export default useFilterVerificationData;
