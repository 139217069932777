import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GETPARAMETERCATEGORY_API,
  GETTYPEWITHOUTPARAMETER_API,
  GETPARAMETERTYPE_API,
} from "@CON";

const simaProSlice = createSlice({
  name: "simaPro",
  initialState: {
    VersionID: undefined,
    companyVersionID: undefined,
    versionList: [],
    CategoryID: undefined,
    categoryList: [],
    TypeID: undefined,
    TypeList: [],
    editCategoryList: [],
    editTypeList: [],
  },
  reducers: {
    setVersionID: (state, { payload }) => {
      state.VersionID = payload;
    },
    setCompanyVersionID: (state, { payload }) => {
      state.companyVersionID = payload;
    },
    setCategoryId: (state, { payload }) => {
      state.CategoryID = payload;
    },
    setTypeId: (state, { payload }) => {
      state.TypeID = payload;
    },

    setEditCategoryList: (state, { payload }) => {
      state.editCategoryList = payload;
    },
    setEditTypeList: (state, { payload }) => {
      state.editTypeList = payload;
    },

    setVersionList: (state, { payload }) => {
      state.versionList = payload;
    },
    setCategoryList: (state, { payload }) => {
      state.categoryList = payload;
    },
    setTypeList: (state, { payload }) => {
      state.TypeList = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVersionById.fulfilled, (state, { payload }) => {
      state.versionList = payload;
    });

    builder.addCase(fetchCategoryById.fulfilled, (state, { payload }) => {
      state.categoryList = payload;
      state.editCategoryList = payload;
    });

    builder.addCase(fetchTypeById.fulfilled, (state, { payload }) => {
      state.TypeList = payload;
    });

    builder.addCase(fetchCategorySelectById.fulfilled, (state, { payload }) => {
      state.editCategoryList = payload;
    });
  },
});

const { reducer, actions } = simaProSlice;
export const {
  setVersionID,
  setCategoryId,
  setTypeId,
  setVersionList,
  setCategoryList,
  setTypeList,
  setCompanyVersionID,
  setEditCategoryList,
  setEditTypeList,
} = actions;

export const fetchVersionById = createAsyncThunk(
  "simaPro/fetchVersionById",
  async ({ versionID = "", CompanyID = "" }, thunkAPI) => {
    // 如果 versionID 為空，則設置為 "0"
    const resolvedVersionID = versionID || "0";
    // 分發 action 設置 versionID
    thunkAPI.dispatch(setVersionID(resolvedVersionID));
      // 發送請求
      const response = await axios.get(
        `${GETPARAMETERCATEGORY_API}/${resolvedVersionID}/${CompanyID}`
      );
    if (response) {
      const customCompanyVersionID = response.find(item => item.paraSource === "自訂公司係數");
      if (customCompanyVersionID) {
        thunkAPI.dispatch(setCompanyVersionID(customCompanyVersionID.ID));
      }
    }
    return response;
  }
);

export const fetchCategoryById = createAsyncThunk(
  "simaPro/fetchCategoryById",
  async (categoryId = "", thunkAPI) => {
    const response = await axios.get(
      `${GETTYPEWITHOUTPARAMETER_API}/${categoryId}`
    );
    return response;
  }
);

export const fetchTypeById = createAsyncThunk(
  "simaPro/fetchTypeById",
  async (typeId = "", thunkAPI) => {
    const response = await axios.get(`${GETPARAMETERTYPE_API}/${typeId}`);
    return response;
  }
);

export const fetchCategorySelectById = createAsyncThunk(
  "simaPro/fetchCategorySelectById",
  async (categoryId = "", thunkAPI) => {
    thunkAPI.dispatch(setCategoryId(categoryId));
    const response = await axios.get(
      `${GETTYPEWITHOUTPARAMETER_API}/${categoryId}`
    );
    return response;
  }
);

export default reducer;
