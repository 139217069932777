import {
  WORKHOURS,
  REFRIGERANT,
  POWERUSAGES,
  GREENPOWERUSAGE,
  VEHICLES,
  KITCHENS,
  FIREEQUIPMENTS,
  STEAMUSAGES,
  OTHERCOMPOUNDS,
  LIFYCYCLE,
  LIFYCYCLEFORPEOPLE,
  COMMUTING,
  PURCHASEDPRODUCT,
  UPSTREAMTRANSPORT,

  // API
  GETLIFECYCLE_API,
  GETCOMMUTING_API,
  GETWORKHOUR_API,
  GETPOWERUSAGE_API,
  GETVEHICLE_API,
  GETKITCHEN_API,
  GETKITCHENFORCOPY_API,
  GETFIREEQUIPMENT_API,
  GETREFRIGERANT_API,
  GETSTEAMUSAGE_API,
  GETOTHERCOMPOUND_API,
  GETPURCHASEDPRODUCT_API,
  GETUPSTREAMTRANSPORT_API,
  // 各類別資料POST
  POSTREFRIGERANT_API,
  POSTFIREEQUIPMENT_API,
  POSTKITCHEN_API,
  POSTVEHICLE_API,
  POSTOTHERCOMPOUND_API,
  POSTLIFECYCLE_API,
  POSTCOMMUTING_API,
  POSTPURCHASEDPRODUCT_API,
  POSTUPSTREAMTRANSPORT_API,
  // update
  UPDATEWORKHOUR_API,
  UPDATEPOWERUSAGE_API,
  UPDATESTEAM_API,
  UPDATEGREENPOWERUSAGE_API,
  UPDATESTEAMUSAGE_API,
  //排放源匯入 Excel
  WORKHOUR_EXCEL_UPLOAD_API,
  WORKHOUR_EXCEL_IMPORT_API,
  POWERUSAGE_EXCEL_UPLOAD_API,
  POWERUSAGE_EXCEL_IMPORT_API,
  FIREEQUIPMENT_EXCEL_UPLOAD_API,
  FIREEQUIPMENT_EXCEL_IMPORT_API,
  REFRIGERANT_EXCEL_UPLOAD_API,
  REFRIGERANT_EXCEL_IMPORT_API,
  OTHERCOMPOUND_EXCEL_UPLOAD_API,
  OTHERCOMPOUND_EXCEL_IMPORT_API,
  KITCHEN_EXCEL_UPLOAD_API,
  KITCHEN_EXCEL_IMPORT_API,
  VEHICLE_EXCEL_UPLOAD_API,
  VEHICLE_EXCEL_IMPORT_API,
  GOSOLINE_EXCEL_UPLOAD_API,
  GOSOLINE_EXCEL_IMPORT_API,
  STEAM_EXCEL_UPLOAD_API,
  STEAM_EXCEL_IMPORT_API,
  LIFECYCLE_EXCEL_IMPORT_API,
  LIFECYCLE_EXCEL_EXPORT_API,
  COMMUTING_EXCEL_IMPORT_API,
  COMMUTING_EXCEL_EXPORT_API,
  MATERIALTRANSPORT_EXCEL_UPLOAD_API,
  MATERIALTRANSPORT_EXCEL_IMPORT_API,
  PURCHASEDPRODUCT_EXCEL_UPLOAD_API,
  PURCHASEDPRODUCT_EXCEL_IMPORT_API,
  // EXPORTS
  WORKHOUR_EXCEL_EXPORT_API,
  FIREEQUIPMENT_EXCEL_EXPORT_API,
  REFRIGERANT_EXCEL_EXPORT_API,
  OTHERCOMPOUND_EXCEL_EXPORT_API,
  KITCHEN_EXCEL_EXPORT_API,
  VEHICLE_EXCEL_EXPORT_API,
  GOSOLINE_EXCEL_EXPORT_API,
  POWERUSAGE_EXCEL_EXPORT_API,
  STEAM_EXCEL_EXPORT_API,
  MATERIALTRANSPORT_EXCEL_EXPORT_API,
  PURCHASEDPRODUCT_EXCEL_EXPORT_API,
  // III匯出
  KITCHEN_EXCEL_EXPORT_III_API,
  VEHICLE_EXCEL_EXPORT_III_API,
  WORKHOUR_EXCEL_EXPORT_III_API,
  REFRIGERANT_EXCEL_EXPORT_III_API,
  FIREEQUIPMENT_EXCEL_EXPORT_III_API,
  POWERUSAGE_EXCEL_EXPORT_III_API,
  STEAM_EXCEL_EXPORT_III_API,
  OTHERCOMPOUND_EXCEL_EXPORT_III_API,
  // 模板下載
  DOWNLOADSAMPLE_OTHER_API,
  DOWNLOADSAMPLE_VEHICLE_API,
  DOWNLOADSAMPLE_KITCHEN_API,
  DOWNLOADSAMPLE_REFRIGERANT_API,
  DOWNLOADSAMPLE_FIREEQUIPMENT_API,
  DOWNLOADSAMPLE_STEAM_API,
  DOWNLOADSAMPLE_POWERUSAGE_API,
  DOWNLOADSAMPLE_WORKHOUR_API,
  DOWNLOADSAMPLE_LIFECYCLE_API,
  DOWNLOADSAMPLE_COMMUTING_API,
  DOWNLOADSAMPLE_MATERIALTRANSPORT_API,
  DOWNLOADSAMPLE_PURCHASEDPRODUCT_API,
  // 資策會模板下載
  DOWNLOADSAMPLE_KITCHEN_III_API,
  DOWNLOADSAMPLE_VEHICLE_III_API,
  DOWNLOADSAMPLE_WORKHOUR_III_API,
  DOWNLOADSAMPLE_REFRIGERANT_III_API,
  DOWNLOADSAMPLE_FIREEQUIPMENT_III_API,
  DOWNLOADSAMPLE_STEAM_III_API,
  DOWNLOADSAMPLE_POWERUSAGE_III_API,
  DOWNLOADSAMPLE_OTHERCOMPOUND_III_API,
  // filterOption
  CAPITALGOOD_OPTION,
  UPSTREAMENERGYEMISSION_OPTION,
  WATER_OPTION,
  DISPOSAL_OPTION,
  LEASEDASSET_OPTION,
  PURCHASEDGOOD_OPTION,
  CONSULTANT_OPTION,
  UPSTREAMTRANSPORT_OPTION,
  BUSINESSTRAVEL_OPTION,
  VISITOR_OPTION,
  COMMUTING_OPTION,
  DISPOSALDOWNTRANSPORT_OPTION,
  STATIONARYCOMBUSTION_OPTION,
  MOBILECOMBUSTION_OPTION,
  DIRECTFUGITIVEEMISSION_OPTION,
  DIRECTPROCESSEMISSION_OPTION,
  OTHERCOMPOUNDS_OPTION,
  ELECTRICITY_OPTION,
  STEAM_OPTION,
  USEEMISSION_OPTION,
  DOWNSTREAMDISPOSAL_OPTION,
  INVESTMENT_OPTION,
  DOWNLEASEDASSET_OPTION,
  DOWNSTREAMTRANSPORT_OPTION,
  OTHER_OPTION,
  // 排放源新增表單
  VEHICLES_ADD_FORM,
  REFRIGERANT_ADD_FORM,
  KITCHENS_ADD_FORM,
  FIREEQUIPMENTS_ADD_FORM,
  OTHERCOMPOUNDS_ADD_FORM,
  LIFECYCLE_ADD_FORM,
  LIFECYCLEFORPEOPLE_ADD_FORM,
  COMMUTING_ADD_FORM,
  PURCHASEDPRODUCT_ADD_FORM,
  UPSTREAMTRANSPORT_ADD_FORM,
  // 公司自訂係數新增表單
  CalculateAddCompanyParaForm,
  FORC1_ADD_FORM,
  POWERUSAGES_ADD_FORM,
  REFRIGERANT_COMPANY_ADD_FORM,
  // DEVICE_ADD_FORM,
  // NONYEAR_DEVICE_ADD_FORM,
  // 填寫人
  WORKHOUR_SHEET_API,
  POWERUSAGE_SHEET_API,
  VEHICLE_SHEET_API,
  KITCHEN_SHEET_API,
  FIREEQUIPMENT_SHEET_API,
  REFRIGERANT_SHEET_API,
  STEAM_SHEET_API,
  OTHERCOMPOUND_SHEET_API,
  LIFECYCLE_SHEET_API,

  // 引用
  COPY_LIFECYCLE_API,
  COPY_PURCHASEDPRODUCT_API,
  COPY_UPSTREAMTRANSPORT_API,
  COPY_COMMUTING_API,
  COPY_OTHER_API,
  COPY_VEHICLE_API,
  COPY_KITCHEN_API,
  COPY_REFRIGERANT_API,
  COPY_FIREEQUIPMENT_API,
  COPY_STEAM_API,
  COPY_POWERUSAGE_API,
  COPY_WORKHOUR_API,
} from "@CON";

const chooseCalculateTable = (param, isEdit) => {
  let tableTitle;
  let apiPathForGet;
  let apiPathForPost;
  let apiPathForUpload;
  let apiPathForImport;
  let apiPathForExport;
  let apiPathForExportIII;
  let formItemForAdd;
  let formCompanyParaForAdd;
  let apiPathForCopy;
  let writerApi;
  let apiPathForSample;
  let apiPathForSampleIII;
  let currentTab = [
    { id: "15", title: "平台引用功能(ex糞肥)" },
    { id: "16", title: "平台匯出&匯入功能(ex冷媒)" },
  ];
  switch (param) {
    case "LifeCycle":
      tableTitle = LIFYCYCLE(isEdit);
      apiPathForGet = GETLIFECYCLE_API;
      apiPathForPost = POSTLIFECYCLE_API;
      formItemForAdd = LIFECYCLE_ADD_FORM;
      writerApi = LIFECYCLE_SHEET_API;
      apiPathForCopy = COPY_LIFECYCLE_API;
      apiPathForSample = DOWNLOADSAMPLE_LIFECYCLE_API;
      apiPathForUpload = LIFECYCLE_EXCEL_IMPORT_API;
      apiPathForExport = LIFECYCLE_EXCEL_EXPORT_API;
      currentTab = [
        {
          id: "7",
          title: "係數資料庫介紹",
        },
        ...currentTab,
      ];
      break;
    case "BusinessTravel":
      tableTitle = COMMUTING(isEdit, param);
      apiPathForGet = GETCOMMUTING_API;
      apiPathForPost = POSTCOMMUTING_API;
      formItemForAdd = COMMUTING_ADD_FORM;
      writerApi = LIFECYCLE_SHEET_API;
      apiPathForCopy = COPY_COMMUTING_API;
      apiPathForSample = DOWNLOADSAMPLE_COMMUTING_API;
      apiPathForUpload = COMMUTING_EXCEL_IMPORT_API;
      apiPathForExport = COMMUTING_EXCEL_EXPORT_API;
      currentTab = [
        {
          id: "6",
          title: "C3員工差旅飛機&高鐵",
        },
        {
          id: "7",
          title: "係數資料庫介紹",
        },
        ...currentTab,
      ];
      break;
    case "Visitor":
      tableTitle = COMMUTING(isEdit, param);
      apiPathForGet = GETCOMMUTING_API;
      apiPathForPost = POSTCOMMUTING_API;
      formItemForAdd = COMMUTING_ADD_FORM;
      writerApi = LIFECYCLE_SHEET_API;
      apiPathForCopy = COPY_COMMUTING_API;
      apiPathForSample = DOWNLOADSAMPLE_COMMUTING_API;
      apiPathForUpload = COMMUTING_EXCEL_IMPORT_API;
      apiPathForExport = COMMUTING_EXCEL_EXPORT_API;
      currentTab = [
        {
          id: "6",
          title: "C3員工差旅飛機&高鐵",
        },
        {
          id: "7",
          title: "係數資料庫介紹",
        },
        ...currentTab,
      ];
      break;

    case "WorkHour":
      tableTitle = WORKHOURS(isEdit);
      apiPathForGet = GETWORKHOUR_API;
      apiPathForPost = UPDATEWORKHOUR_API;
      apiPathForUpload = WORKHOUR_EXCEL_UPLOAD_API;
      apiPathForImport = WORKHOUR_EXCEL_IMPORT_API;
      apiPathForExport = WORKHOUR_EXCEL_EXPORT_API;
      apiPathForExportIII = WORKHOUR_EXCEL_EXPORT_III_API;
      writerApi = WORKHOUR_SHEET_API;
      apiPathForCopy = COPY_WORKHOUR_API;
      apiPathForSample = DOWNLOADSAMPLE_WORKHOUR_API;
      apiPathForSampleIII = DOWNLOADSAMPLE_WORKHOUR_III_API;
      currentTab = [
        {
          id: "2",
          title: "C1人員逸散糞肥管理",
        },
        ...currentTab,
      ];
      break;
    case "Refrigerant":
      tableTitle = REFRIGERANT(isEdit);
      apiPathForGet = GETREFRIGERANT_API;
      formItemForAdd = REFRIGERANT_ADD_FORM;
      formCompanyParaForAdd = REFRIGERANT_COMPANY_ADD_FORM;
      apiPathForPost = POSTREFRIGERANT_API;
      apiPathForUpload = REFRIGERANT_EXCEL_UPLOAD_API;
      apiPathForImport = REFRIGERANT_EXCEL_IMPORT_API;
      apiPathForExport = REFRIGERANT_EXCEL_EXPORT_API;
      apiPathForExportIII = REFRIGERANT_EXCEL_EXPORT_III_API;
      writerApi = REFRIGERANT_SHEET_API;
      apiPathForCopy = COPY_REFRIGERANT_API;
      apiPathForSample = DOWNLOADSAMPLE_REFRIGERANT_API;
      apiPathForSampleIII = DOWNLOADSAMPLE_REFRIGERANT_III_API;
      currentTab = [
        {
          id: "3",
          title: "C1人員逸散冷媒資料&單筆新增",
        },
        ...currentTab,
      ];
      break;
    case "PowerUsages":
      tableTitle = POWERUSAGES(isEdit);
      apiPathForGet = GETPOWERUSAGE_API;
      apiPathForPost = UPDATEPOWERUSAGE_API;
      formCompanyParaForAdd = POWERUSAGES_ADD_FORM;
      apiPathForUpload = POWERUSAGE_EXCEL_UPLOAD_API;
      apiPathForImport = POWERUSAGE_EXCEL_IMPORT_API;
      apiPathForExport = POWERUSAGE_EXCEL_EXPORT_API;
      apiPathForExportIII = POWERUSAGE_EXCEL_EXPORT_III_API;
      writerApi = POWERUSAGE_SHEET_API;
      apiPathForCopy = COPY_POWERUSAGE_API;
      apiPathForSample = DOWNLOADSAMPLE_POWERUSAGE_API;
      apiPathForSampleIII = DOWNLOADSAMPLE_POWERUSAGE_III_API;
      currentTab = [
        {
          id: "4",
          title: "C2電力輸入",
        },
        ...currentTab,
      ];
      break;
    case "GreenPowerUsages":
      tableTitle = GREENPOWERUSAGE(isEdit);
      apiPathForGet = GETPOWERUSAGE_API;
      apiPathForPost = UPDATEGREENPOWERUSAGE_API;
      apiPathForUpload = POWERUSAGE_EXCEL_UPLOAD_API;
      apiPathForImport = POWERUSAGE_EXCEL_IMPORT_API;
      apiPathForExport = POWERUSAGE_EXCEL_EXPORT_API;
      apiPathForExportIII = POWERUSAGE_EXCEL_EXPORT_III_API;
      writerApi = POWERUSAGE_SHEET_API;
      apiPathForCopy = COPY_POWERUSAGE_API;
      apiPathForSample = DOWNLOADSAMPLE_POWERUSAGE_API;
      apiPathForSampleIII = DOWNLOADSAMPLE_POWERUSAGE_III_API;
      currentTab = [
        {
          id: "4",
          title: "C2電力輸入",
        },
        ...currentTab,
      ];
      break;
    case "Vehicles":
      tableTitle = VEHICLES(isEdit);
      apiPathForPost = POSTVEHICLE_API;
      apiPathForGet = GETVEHICLE_API;
      formItemForAdd = VEHICLES_ADD_FORM;
      formCompanyParaForAdd = FORC1_ADD_FORM;
      apiPathForUpload = VEHICLE_EXCEL_UPLOAD_API;
      apiPathForImport = VEHICLE_EXCEL_IMPORT_API;
      apiPathForExport = VEHICLE_EXCEL_EXPORT_API;
      apiPathForExportIII = VEHICLE_EXCEL_EXPORT_III_API;
      writerApi = VEHICLE_SHEET_API;
      apiPathForCopy = COPY_VEHICLE_API;
      apiPathForSample = DOWNLOADSAMPLE_VEHICLE_API;
      apiPathForSampleIII = DOWNLOADSAMPLE_VEHICLE_III_API;
      currentTab = [
        {
          id: "1",
          title: "C1固定源&移動源",
        },
        ...currentTab,
      ];
      break;
    case "Kitchens":
      tableTitle = KITCHENS(isEdit);
      apiPathForGet = GETKITCHEN_API;
      formItemForAdd = KITCHENS_ADD_FORM;
      formCompanyParaForAdd = FORC1_ADD_FORM;
      apiPathForPost = POSTKITCHEN_API;
      apiPathForUpload = KITCHEN_EXCEL_UPLOAD_API;
      apiPathForImport = KITCHEN_EXCEL_IMPORT_API;
      apiPathForExport = KITCHEN_EXCEL_EXPORT_API;
      apiPathForExportIII = KITCHEN_EXCEL_EXPORT_III_API;
      writerApi = KITCHEN_SHEET_API;
      apiPathForCopy = COPY_KITCHEN_API;
      apiPathForSample = DOWNLOADSAMPLE_KITCHEN_API;
      apiPathForSampleIII = DOWNLOADSAMPLE_KITCHEN_III_API;
      currentTab = [
        {
          id: "1",
          title: "C1固定源&移動源",
        },
        ...currentTab,
      ];
      break;
    case "Process":
      tableTitle = KITCHENS(isEdit);
      apiPathForGet = GETKITCHEN_API;
      formItemForAdd = KITCHENS_ADD_FORM;
      formCompanyParaForAdd = FORC1_ADD_FORM;
      apiPathForPost = POSTKITCHEN_API;
      apiPathForUpload = KITCHEN_EXCEL_UPLOAD_API;
      apiPathForImport = KITCHEN_EXCEL_IMPORT_API;
      apiPathForExport = KITCHEN_EXCEL_EXPORT_API;
      apiPathForExportIII = KITCHEN_EXCEL_EXPORT_III_API;
      writerApi = KITCHEN_SHEET_API;
      apiPathForCopy = COPY_KITCHEN_API;
      apiPathForSample = DOWNLOADSAMPLE_KITCHEN_API;
      apiPathForSampleIII = DOWNLOADSAMPLE_KITCHEN_III_API;
      currentTab = [
        {
          id: "1",
          title: "C1固定源&移動源",
        },
        ...currentTab,
      ];
      break;
    case "FireEquipment":
      tableTitle = FIREEQUIPMENTS(isEdit);
      apiPathForGet = GETFIREEQUIPMENT_API;
      formItemForAdd = FIREEQUIPMENTS_ADD_FORM;
      // formCompanyParaForAdd = NONYEAR_DEVICE_ADD_FORM;
      apiPathForPost = POSTFIREEQUIPMENT_API;
      apiPathForUpload = FIREEQUIPMENT_EXCEL_UPLOAD_API;
      apiPathForImport = FIREEQUIPMENT_EXCEL_IMPORT_API;
      apiPathForExport = FIREEQUIPMENT_EXCEL_EXPORT_API;
      apiPathForExportIII = FIREEQUIPMENT_EXCEL_EXPORT_III_API;
      writerApi = FIREEQUIPMENT_SHEET_API;
      apiPathForCopy = COPY_FIREEQUIPMENT_API;
      apiPathForSample = DOWNLOADSAMPLE_FIREEQUIPMENT_API;
      apiPathForSampleIII = DOWNLOADSAMPLE_FIREEQUIPMENT_III_API;
      break;
    case "SteamUsages":
      tableTitle = STEAMUSAGES(isEdit);
      apiPathForGet = GETSTEAMUSAGE_API;
      apiPathForPost = UPDATESTEAMUSAGE_API;
      formCompanyParaForAdd = FORC1_ADD_FORM;
      apiPathForUpload = STEAM_EXCEL_UPLOAD_API;
      apiPathForImport = STEAM_EXCEL_IMPORT_API;
      apiPathForExport = STEAM_EXCEL_EXPORT_API;
      apiPathForExportIII = STEAM_EXCEL_EXPORT_III_API;
      writerApi = STEAM_SHEET_API;
      apiPathForCopy = COPY_STEAM_API;
      apiPathForSample = DOWNLOADSAMPLE_STEAM_API;
      apiPathForSampleIII = DOWNLOADSAMPLE_STEAM_III_API;

      break;
    case "OtherCompounds":
      tableTitle = OTHERCOMPOUNDS(isEdit);
      apiPathForGet = GETOTHERCOMPOUND_API;
      apiPathForPost = POSTOTHERCOMPOUND_API;
      formItemForAdd = OTHERCOMPOUNDS_ADD_FORM;
      apiPathForUpload = OTHERCOMPOUND_EXCEL_UPLOAD_API;
      apiPathForImport = OTHERCOMPOUND_EXCEL_IMPORT_API;
      apiPathForExport = OTHERCOMPOUND_EXCEL_EXPORT_API;
      apiPathForExportIII = OTHERCOMPOUND_EXCEL_EXPORT_III_API;
      writerApi = OTHERCOMPOUND_SHEET_API;
      apiPathForCopy = COPY_OTHER_API;
      apiPathForSample = DOWNLOADSAMPLE_OTHER_API;
      apiPathForSampleIII = DOWNLOADSAMPLE_OTHERCOMPOUND_III_API;

      break;
    case "Commuting":
      tableTitle = COMMUTING(isEdit, param);
      apiPathForGet = GETCOMMUTING_API;
      apiPathForPost = POSTCOMMUTING_API;
      formItemForAdd = COMMUTING_ADD_FORM;
      writerApi = LIFECYCLE_SHEET_API;
      apiPathForCopy = COPY_COMMUTING_API;
      apiPathForUpload = COMMUTING_EXCEL_IMPORT_API;
      apiPathForExport = COMMUTING_EXCEL_EXPORT_API;
      apiPathForSample = DOWNLOADSAMPLE_COMMUTING_API;
      currentTab = [
        {
          id: "5",
          title: "C3員工通勤",
        },
        {
          id: "7",
          title: "係數資料庫介紹",
        },
        ...currentTab,
      ];

      break;
    // case "DisposalDownTransport":
    //   tableTitle = LIFYCYCLE;
    //   apiPathForGet = GETLIFECYCLE_API;
    //   apiPathForPost = POSTLIFECYCLE_API;
    //   formItemForAdd = LIFECYCLE_ADD_FORM;
    //   writerApi = LIFECYCLE_SHEET_API;
    //   apiPathForCopy = COPY_LIFECYCLE_API;
    //   break;
    case "PurchasedGood":
      tableTitle = PURCHASEDPRODUCT(isEdit);
      formItemForAdd = PURCHASEDPRODUCT_ADD_FORM;
      apiPathForGet = GETPURCHASEDPRODUCT_API;
      apiPathForPost = POSTPURCHASEDPRODUCT_API;
      writerApi = LIFECYCLE_SHEET_API;
      apiPathForCopy = COPY_PURCHASEDPRODUCT_API;
      apiPathForSample = DOWNLOADSAMPLE_PURCHASEDPRODUCT_API;
      apiPathForUpload = PURCHASEDPRODUCT_EXCEL_UPLOAD_API;
      apiPathForExport = PURCHASEDPRODUCT_EXCEL_EXPORT_API;
      apiPathForImport = PURCHASEDPRODUCT_EXCEL_IMPORT_API;
      currentTab = [
        {
          id: "7",
          title: "係數資料庫介紹",
        },
        ...currentTab,
      ];
      break;
    case "UpstreamTransport":
      tableTitle = UPSTREAMTRANSPORT(isEdit);
      formItemForAdd = UPSTREAMTRANSPORT_ADD_FORM;
      apiPathForGet = GETUPSTREAMTRANSPORT_API;
      apiPathForPost = POSTUPSTREAMTRANSPORT_API;
      writerApi = LIFECYCLE_SHEET_API;
      apiPathForCopy = COPY_UPSTREAMTRANSPORT_API;
      apiPathForUpload = MATERIALTRANSPORT_EXCEL_UPLOAD_API;
      apiPathForExport = MATERIALTRANSPORT_EXCEL_EXPORT_API;
      apiPathForImport = MATERIALTRANSPORT_EXCEL_IMPORT_API;
      apiPathForSample = DOWNLOADSAMPLE_MATERIALTRANSPORT_API;
      currentTab = [
        {
          id: "7",
          title: "係數資料庫介紹",
        },
        ...currentTab,
      ];
      break;
    default:
      break;
  }
  return {
    tableTitle,
    apiPathForGet,
    apiPathForUpload,
    apiPathForImport,
    formItemForAdd,
    formCompanyParaForAdd,
    apiPathForPost,
    apiPathForExport,
    apiPathForExportIII,
    writerApi,
    apiPathForCopy,
    apiPathForSample,
    apiPathForSampleIII,
    currentTab,
  };
};

// 對應 sourceIconList 的 link ==> 排放源頁簽
const chooseCalculateFilterOption = (param) => {
  let filterOptionList = [];
  switch (param) {
    // 上游 sourceIconListForUpstreamS3
    case "capitalGood":
      filterOptionList = CAPITALGOOD_OPTION;
      break;
    case "upstreamEmissions":
      filterOptionList = UPSTREAMENERGYEMISSION_OPTION;
      break;
    case "waterUsage":
      filterOptionList = WATER_OPTION;
      break;
    case "disposal":
      filterOptionList = DISPOSAL_OPTION;
      break;
    case "leasedAsset":
      filterOptionList = LEASEDASSET_OPTION;
      break;
    case "purchasedGood":
      filterOptionList = PURCHASEDGOOD_OPTION;
      break;
    case "consultant":
      filterOptionList = CONSULTANT_OPTION;
      break;
    case "upstreamTransport":
      filterOptionList = UPSTREAMTRANSPORT_OPTION;
      break;

    // 事業 sourceIconListForBusinessS1
    case "businessTravel":
      filterOptionList = BUSINESSTRAVEL_OPTION;
      break;
    case "visitor":
      filterOptionList = VISITOR_OPTION;
      break;
    case "commuting":
      filterOptionList = COMMUTING_OPTION;
      break;
    case "disposalDownTransport":
      filterOptionList = DISPOSALDOWNTRANSPORT_OPTION;
      break;

    case "stationaryCombustion":
      filterOptionList = STATIONARYCOMBUSTION_OPTION;
      break;
    case "mobileCombustion":
      filterOptionList = MOBILECOMBUSTION_OPTION;
      break;
    case "directFugitiveEmission":
      filterOptionList = DIRECTFUGITIVEEMISSION_OPTION;
      break;
    case "directProcessEmission":
      filterOptionList = DIRECTPROCESSEMISSION_OPTION;
      break;
    case "otherCompound":
      filterOptionList = OTHERCOMPOUNDS_OPTION;

      break;

    // 事業 sourceIconListForBusinessS2
    case "electricity":
      filterOptionList = ELECTRICITY_OPTION;
      break;
    case "steam":
      filterOptionList = STEAM_OPTION;
      break;

    // 下游 sourceIconListForDownstreamS3
    case "useEmission":
      filterOptionList = USEEMISSION_OPTION;
      break;
    case "downstreamDisposal":
      filterOptionList = DOWNSTREAMDISPOSAL_OPTION;
      break;
    case "investment":
      filterOptionList = INVESTMENT_OPTION;
      break;
    case "downLeasedAsset":
      filterOptionList = DOWNLEASEDASSET_OPTION;
      break;
    case "downstreamTransport":
      filterOptionList = DOWNSTREAMTRANSPORT_OPTION;
      break;

    //sourceIconListForOtherS3
    case "other":
      filterOptionList = OTHER_OPTION;
      break;

    default:
      break;
  }
  return { filterOptionList };
};

export { chooseCalculateTable, chooseCalculateFilterOption };
