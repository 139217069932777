import { useState, useEffect } from "react";
import { colorGenerator } from "@UTIL";
import { useTranslation } from "react-i18next";


export default function useScopeChart(rawData) {
  const { t } = useTranslation();
  const LABEL_LIST = [
    { key: "StationaryCombustion", labelName: t("Calculate.stationary"), category: "C1" },
    { key: "MobileCombustion", labelName: t("Calculate.mobile"), category: "C1" },
    { key: "IndustrialProcesses", labelName: t("Calculate.ProcessEmission"), category: "C1" },
    { key: "AnthropogenicFugitive", labelName: t("Calculate.Anthropogenic"), category: "C1" },
    { key: "LandUse", labelName: t("Calculate.LandUse"), category: "C1" },
    { key: "Elec", labelName: t("Calculate.Elec"), category: "C2" },
    { key: "Energy", labelName: t("Calculate.Energy"), category: "C2" },
    { key: "UpstreamTransport", labelName: t("Calculate.UpstreamTrans"), category: "C3" },
    { key: "DownstreamTransport", labelName: t("Calculate.DownstreamTrans"), category: "C3" },
    { key: "EmployeeCommuting", labelName: t("Calculate.EmployeeCommuting"), category: "C3" },
    { key: "CVTransportation", labelName: t("Calculate.CVTransportation"), category: "C3" },
    { key: "BusinessTravel", labelName: t("Calculate.BIZTravel"), category: "C3" },
    { key: "DisposalDownTransport", labelName: t("Calculate.DDTransport"), category: "C3" },
    { key: "WaterUsage", labelName: t("Calculate.WUsage"), category: "C4" },
    { key: "BuyGoods", labelName: t("Calculate.BuyGoods"), category: "C4" },
    { key: "EnergyProcurement", labelName: t("Calculate.EnergyProcurement"), category: "C4" },
    { key: "CapitalGoods", labelName: t("Calculate.CapitalGoods"), category: "C4" },
    { key: "WasteDisposal", labelName: t("Calculate.WasteDisposal"), category: "C4" },
    { key: "UpstreamLeasedAssets", labelName: t("Calculate.Upstreamleasedassets"), category: "C4" },
    { key: "ConsultantCleaning", labelName: t("Calculate.ConsultantCleaning"), category: "C4" },
    { key: "DownstreamLeasedAssets", labelName: t("Calculate.DownstreamLeasedAssets"), category: "C5" },
    { key: "ProductUse", labelName: t("Calculate.ProductUse"), category: "C5" },
    { key: "Invest", labelName: t("Calculate.Invest"), category: "C5" },
    { key: "ProductEndOfLife", labelName: t("Calculate.ProductEndOfLife"), category: "C5" },
    { key: "Other", labelName: t("Calculate.OtherEmissions"), category: "C6" },
  ];
  const [allData, setChartData] = useState(rawData);
  const [flattenData, setFlattenData] = useState([]);
  const [chartData, setData] = useState({
    labels: [],
    datasets: [],
  });
  const flatObg = (obj) => {
    let newObj = {};
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === "object") {
        newObj = { ...newObj, ...obj[key] };
      } else {
        newObj = { ...newObj, [key]: obj[key] };
      }
    });
    return newObj;
  };

  const formatData = (allData, list) => {
    const formatData = allData?.map(flatObg);
    setFlattenData(formatData);
    const datasets = formatData.map((fab, index) => ({
      label: fab.FabName + fab.Year,
      data: list.map(({ key }) => fab[key]),
      backgroundColor: colorGenerator(index),
    }));
    setData({
      labels: list?.map(({ labelName }) => labelName),
      datasets,
    });
  };

  const [categoryListForChart, setCategoryListForChart] = useState([]);

  // 決定目前進入者可視範圍
  const determineScope = (list) => {
    const listForChart = LABEL_LIST.filter(({ category }) =>
      list.includes(category)
    );
    setCategoryListForChart(listForChart);
  };

  useEffect(() => {
    formatData(allData, categoryListForChart);
  }, [allData, categoryListForChart]);

  return {
    flattenData,
    chartData,
    setChartData,
    categoryListForChart,
    determineScope,
  };
}
