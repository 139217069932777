import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchLangByKey } from "@STORE";

export default function Language({ children }) {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const {
    pageInfo: { antdLanguage },
  } = useSelector((state) => state);
  useEffect(() => {
    let lang = antdLanguage || navigator.language.toLowerCase();
    if (lang.includes("en")) {
      lang = "en-us";
    }
    if (lang.includes("vi")) {
      lang = "vi-vn";
    }
    console.log(`Language ` + lang);
    i18n.changeLanguage(lang);
    dispatch(fetchLangByKey(lang));
  }, []);

  return <>{children}</>;
}
