import React, { useMemo, useContext, useEffect } from "react";
import {
  AdvancedMarker,
  InfoWindow,
  useAdvancedMarkerRef,
  Pin,
} from "@vis.gl/react-google-maps";
import { GoogleMapPlaceContext } from "./CalculateGoogleMapModal";

export const CalculateGoogleMapMarker = () => {
  const GoogleMapContext = useContext(GoogleMapPlaceContext);
  const [oriMarkerRef, oriMarker] = useAdvancedMarkerRef();
  const [desMarkerRef, desMarker] = useAdvancedMarkerRef();

  const ori = useMemo(
    () => ({
      address: GoogleMapContext.selectedPlace.ori?.formatted_address,
      pos: {
        lat: GoogleMapContext.selectedPlace.ori?.geometry?.location.lat(),
        lng: GoogleMapContext.selectedPlace.ori?.geometry?.location.lng(),
      },
    }),
    [GoogleMapContext]
  );

  const des = useMemo(
    () => ({
      address: GoogleMapContext.selectedPlace.des?.formatted_address,
      pos: {
        lat: GoogleMapContext.selectedPlace.des?.geometry?.location.lat(),
        lng: GoogleMapContext.selectedPlace.des?.geometry?.location.lng(),
      },
    }),
    [GoogleMapContext]
  );

  useEffect(() => {
    console.log(ori, des);
  }, [ori, des]);

  return (
    <>
      {ori.pos.lat && (
        <AdvancedMarker ref={oriMarkerRef} position={ori?.pos}>
          <Pin>
            <span className="text-white">A</span>
          </Pin>
        </AdvancedMarker>
        // <div>

        //   <InfoWindow
        //     anchor={oriMarker}
        //     maxWidth={200}
        //     style={{ position: "absolute", top: 0, zIndex: 1000 }}
        //   >
        //     {ori?.address}
        //   </InfoWindow>
        // </div>
      )}

      {des.pos.lat && (
        <AdvancedMarker ref={desMarkerRef} position={des?.pos}>
          <Pin>
            <span className="text-white">B</span>
          </Pin>
        </AdvancedMarker>
        // <div>
        //   <InfoWindow anchor={desMarker} maxWidth={200}>
        //     {ori?.address}
        //   </InfoWindow>
        // </div>
      )}
    </>
  );
};
