import React, {
  useEffect,
  useState,
  createContext,
  useRef,
  useContext,
} from "react";
import { Button, Modal, Form, Input, Row, Col } from "antd";
import { APIProvider, Map, useMap } from "@vis.gl/react-google-maps";
import CalculateGoogleMapForm from "./CalculateGoogleMapForm";
import CalculateGoogleMapDirection from "./CalculateGoogleMapDirection";
import FileSaver from "file-saver";
import { FormContext } from "./CalculateGoogleMap";
import html2canvas from "html2canvas";
import { dataURLtoFile, messageGenerator } from "@UTIL";
import { CalculateGoogleMapMarker } from "./CalculateGoogleMapMarker";

export const GoogleMapPlaceContext = createContext(null);

function CalculateGoogleMapModal({ totalDistance, setTotalDistance }) {
  const context = useContext(FormContext);

  // 取得起點終點
  const [selectedPlace, setSelectedPlace] = useState({ ori: null, des: null });
  const getCoordinate = (address) => {
    if (!address) return null;
    return new Promise((resolve, reject) => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === "OK") {
          console.log({
            formatted_address: address,
            ...results[0],
          });
          resolve({
            formatted_address: address,
            ...results[0],
            isInitial: true,
          });
        } else {
          messageGenerator({
            content:
              "Geocode was not successful for the following reason: " + status,
          });
          //alert('Geocode was not successful for the following reason: ' + status);
        }
      });
    });
  };

  const getInitialData = async (values) => {
    const origin = await getCoordinate(values[context.ori]);
    const destination = await getCoordinate(values[context.des]);
    setSelectedPlace({ ori: origin, des: destination });
  };

  useEffect(() => {
    if (context.isModalOpen) {
      const values = context.form.getFieldsValue();
      console.log(values);
      getInitialData(values);
    }
  }, [context.isModalOpen]);

  const map = useRef(null);
  const googleMap = useMap("googleMap");
  const [allTilesLoaded, setAllTilesLoaded] = useState(false);

  const handleOk = () => {
    if (!allTilesLoaded) return;
    const origin = selectedPlace.ori?.formatted_address || "";
    const destination = selectedPlace.des?.formatted_address || "";
    context.form.setFieldValue(context.ori, origin);
    context.form.setFieldValue(context.des, destination);
    if (origin && destination) {
      context.form.setFieldValue(context.distance, totalDistance);

      html2canvas(
        map.current,
        // This is a very important line as it allows to capture an image of Google Maps!
        { useCORS: true }
      ).then((canvas) => {
        if (context.onRemove) {
          context.onRemove();
        }
        const file = dataURLtoFile(canvas?.toDataURL(), `googlemap.jpeg`);
        const src = URL.createObjectURL(file);
        // googleMapImg.current.src=src;
        context.setImageSrc(src);
        // context.googleMapImg.current.onload = function () {
        //   URL.revokeObjectURL(googleMapImg.current.src); // free memory
        // };
        // FileSaver.saveAs(file);
        // 呼叫 onSaveImage 將檔案傳遞回父元件
        context.form?.setFieldValue("referencePhoto", [file]);
        //context.onSaveImage && context.onSaveImage(file);
        context.handleCancel();
      });
    } else {
      setTotalDistance(0);
      context.form.setFieldValue(context.distance, 0);
      context.handleCancel();
    }
  };

  const [center, setCenter] = useState({ lat: 0, lng: 0 });

  useEffect(() => {
    const successCallback = (position) => {
      const { latitude: lat, longitude: lng } = position.coords;
      setCenter({ lat, lng });
    };

    const errorCallback = (error) => {
      console.log(error);
    };

    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  }, []);

  return (
    <Modal
      title="Google 距離計算"
      open={context.isModalOpen}
      onOk={handleOk}
      onCancel={context.handleCancel}
      width={1000}
      destroyOnClose={true}
      footer={[
        <div className="d-flex justify-content-between">
          <div>距離: {totalDistance} KM</div>
          <div>
            <Button key="cancel" onClick={context.handleCancel}>
              取消
            </Button>
            <Button key="submit" type="primary" onClick={handleOk}>
              確認
            </Button>
          </div>
        </div>,
      ]}
    >
      <GoogleMapPlaceContext.Provider value={{ selectedPlace }}>
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
          <Row gutter={16}>
            <Col span={8}>
              <CalculateGoogleMapDirection
                setTotalDistance={setTotalDistance}
                setAllTilesLoaded={setAllTilesLoaded}
              >
                <CalculateGoogleMapForm setSelectedPlace={setSelectedPlace} />
              </CalculateGoogleMapDirection>
            </Col>
            <Col span={16}>
              <div ref={map} className="">
                <div className="bg-white p-2 d-flex flex-column w-100">
                  <span>
                    起點（Ａ）：{selectedPlace.ori?.formatted_address}
                  </span>
                  <span>
                    終點（Ｂ）：{selectedPlace.des?.formatted_address}
                  </span>
                  <span>總距離：{totalDistance} KM</span>
                </div>
                <Map
                  defaultZoom={10}
                  defaultCenter={center}
                  gestureHandling={"greedy"}
                  disableDefaultUI={true}
                  style={{ width: "100%", height: "500px" }}
                  mapId="googleMap"
                >
                  <CalculateGoogleMapMarker />
                </Map>
              </div>
            </Col>
          </Row>
        </APIProvider>
      </GoogleMapPlaceContext.Provider>
    </Modal>
  );
}

export default CalculateGoogleMapModal;
