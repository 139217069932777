import React, { useEffect, useState, useMemo, useContext } from "react";
import { Button, Tabs } from "antd";
import { useMap, Map, useMapsLibrary } from "@vis.gl/react-google-maps";
import { GoogleMapPlaceContext } from "./CalculateGoogleMapModal";
export default function CalculateGoogleMapDirection({
  children,
  setTotalDistance,
  setAllTilesLoaded,
}) {
  const GoogleMapContext = useContext(GoogleMapPlaceContext);

  const map = useMap();
  const routesLibrary = useMapsLibrary("routes");
  const [directionsService, setDirectionsService] = useState();
  const [directionsRenderer, setDirectionsRenderer] = useState();
  const [routes, setRoutes] = useState([]);
  const [routeIndex, setRouteIndex] = useState(0);

  useEffect(() => {
    routes.length > 0 &&
      setTotalDistance(routes[routeIndex]?.legs[0].distance?.value / 1000);
  }, [routes, routeIndex, setTotalDistance]);

  const [travelMode, setTravelMode] = useState("DRIVING");

  // Initialize directions service and renderer
  useEffect(() => {
    if (!routesLibrary || !map) return;
    window.google.maps.event.addListenerOnce(map, "tilesloaded", function () {
      // do something only the first time the map is loaded
      console.log("Google Map is ready");
      setAllTilesLoaded(true);
    });

    setDirectionsService(new routesLibrary.DirectionsService());
    setDirectionsRenderer(
      new routesLibrary.DirectionsRenderer({
        map,
        suppressMarkers: true,
        polylineOptions: { strokeColor: "#8b0013" },
      })
    );
  }, [routesLibrary, map]);

  const onClick = (e) => {
    e && e.preventDefault();
    if (!directionsService || !directionsRenderer) return;

    directionsService
      .route({
        origin: GoogleMapContext.selectedPlace.ori.formatted_address,
        destination: GoogleMapContext.selectedPlace.des.formatted_address,
        travelMode: window.google.maps.TravelMode[travelMode],
        provideRouteAlternatives: true,
      })
      .then((response) => {
        directionsRenderer.setDirections(response);
        setRoutes(response.routes);
        const bounds = new window.google.maps.LatLngBounds();
        bounds.extend({
          lat: GoogleMapContext.selectedPlace.ori.geometry?.location.lat(),
          lng: GoogleMapContext.selectedPlace.ori.geometry?.location.lng(),
        });
        bounds.extend({
          lat: GoogleMapContext.selectedPlace.des.geometry?.location.lat(),
          lng: GoogleMapContext.selectedPlace.des.geometry?.location.lng(),
        });
        map.fitBounds(bounds);
      });
  };

  // Use directions service
  useEffect(() => {
    if (!directionsRenderer) return;
  
    return () => directionsRenderer.setMap(null);
  }, [directionsService, directionsRenderer]);

  useEffect(() => {
    if (GoogleMapContext.selectedPlace.ori?.isInitial &&
      GoogleMapContext.selectedPlace.des?.isInitial)  {
        onClick()
      };
   
  
  }, [GoogleMapContext]);

  // Update direction route
  useEffect(() => {
    if (!directionsRenderer) return;
    directionsRenderer.setRouteIndex(routeIndex);
  }, [routeIndex, directionsRenderer]);

  const onChange = (key) => {
    setTravelMode(key);
  };
  const items = [
    {
      key: "DRIVING",
      label: "DRIVING",
    },
    {
      key: "WALKING",
      label: "WALKING",
    },
    {
      key: "BICYCLING",
      label: "BICYCLING",
    },
    {
      key: "TRANSIT",
      label: "TRANSIT",
    },
  ];

  return (
    <div className="directions">
      {/* <Tabs defaultActiveKey="DRIVING" items={items} onChange={onChange} /> */}
      {children}
      <Button
        color="default"
        variant="solid"
        block
        onClick={onClick}
        disabled={
          !GoogleMapContext.selectedPlace.ori?.formatted_address ||
          !GoogleMapContext.selectedPlace.des?.formatted_address
        }
      >
        查詢
      </Button>
    </div>
  );
}
