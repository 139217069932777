import { notification, List } from "antd";
export default function messageGenerator({
  result = "error",
  content,
  description = null,
  duration = 5,
  key = "",
  placement = "top",
}) {
  // message.open({
  //   type: result,
  //   content,
  //   duration,
  //   key,
  //   style: {
  //     margin: "20px",
  //   },
  // });
  notification.open({
    type: result,
    message: content,
    placement,
    description: description ? (
      <List
        className="ant-modal-body"
        dataSource={description}
        renderItem={(item) => <List.Item>◆ {item}。</List.Item>}
      />
    )  : null,
    duration,
    key,
    style: {
      fontSize: "24px",
    },
    className: "custom_notification",
  });
}
