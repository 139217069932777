import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { CHANGELANG_API, CULTUERSET_API } from "@CON";

export const fetchLangByKey = createAsyncThunk(
  "pageInfo/fetchLangByKey",
  async (lang, { dispatch }) => {
    // 發送 POST 請求進行語言切換
    await axios.post(`${CHANGELANG_API}/${lang}`);
    //語言切換完成後發送 GET 請求，獲取文化設定資料
    await axios.get(`${CULTUERSET_API}/${lang}`); // 這裡執行 GET 請求
    // 因為你不需要對結果做任何處理，直接返回 lang
    return lang; // 返回原始的 lang
  }
);

const pageInfoSlice = createSlice({
  name: "pageInfo",
  initialState: {
    antdLanguage: "zh-tw",
    breadcrumbItem: [
      {
        title: "首頁",
        key: "/",
      },
    ],
    barMenu: "app",
  },
  reducers: {
    setAntdLangage(state, { payload }) {
      state.antdLanguage = payload;
    },
    setBarMenu(state, { payload }) {
      state.barMenu = payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchLangByKey.fulfilled, (state, { payload }) => {
      // Add user to the state array
      state.antdLanguage = payload;
    });
  },
});

const { reducer, actions } = pageInfoSlice;
export const { setAntdLangage, setBarMenu } = actions;
export default reducer;
